export default {
  computed: {
    borderColorPrimary() {
      return {
        borderColor: `${this.$voicer.getConfig(
          'metadatas.colorPrimary'
        )} !important`,
      }
    },
  },
}
