import { ConceptionError } from '../utils/error'

/**
 * @api public
 * @class
 * Register
 *
 * @description
 * This service provide some usefull methods to help clients
 * to interact with the registration system of the public API
 */

class Register {
  constructor() {
    this.passwordToken = null
    this.user = this.__user
  }

  /**
   * @api public
   * @description
   * Simple abstract method to user.post
   */
  create(payload) {
    if (this.user.exists()) {
      this.user.destroy()
    }
    return this.user.post(payload)
  }

  /**
   * @api public
   * @param {string} uid
   * @description
   * Resurrect a previously created user
   * Usefull if you wanna activate an account when you
   * have lost app context (user object)
   */
  resurrect(uid) {
    if (!this.user.exists()) {
      this.user.clean()
      this.user.$rehydratation({
        id: uid,
      })
    }

    return this
  }

  /**
   * @api public
   * @description
   * Create user password
   */
  createPassword(password) {
    if (!this.passwordToken) {
      throw new ConceptionError(
        'missing validateCode token, you should call validateCode before'
      )
    }

    return this.user.putPassword(password, this.passwordToken).then((data) => {
      this.passwordToken = null

      return data
    })
  }

  /**
   * @api public
   * @param {string} code the validation code
   * @description
   * Generate an API call to /users/{id}/validate
   */
  async validateCode(code) {
    if (!this.user.exists()) {
      throw new ConceptionError(
        'missing user entity - have you forgot the register.save() call or forgot to catch exceptions from the API call ?'
      )
    }

    const { item } = await this.__http.post(`/users/activate`, {
      user: this.user.id,
      code,
    })

    this.user.session.storeToken()
    this.user.$rehydratation(item)

    this.user.emit('authenticated')

    return item
  }
}

export default Register
