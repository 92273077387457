import $get from 'lodash.get'
import { getShellConfig } from '~/utils/voicer'

export default {
  getters: {
    collections(state) {
      const collectionsTags = getShellConfig('universes', []).filter(
        (universe) => {
          return universe.type === 'tag' || universe.type === 'all'
        }
      )
      return [
        ...collectionsTags,
        ...$get(state, 'user.protected.segments', []).filter(
          (segment) => segment.isCollection === true
        ),
      ]
    },
    segments(state) {
      return $get(state, 'user.protected.segments', [])
    },
  },
}
