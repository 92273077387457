/**
 * @api public
 * @description project configuration
 */
export const DEFAULT_CONFIGURATION = {
  apiMode: 'development',
  apiVersion: 'lts',
  axios: undefined,
  debug: false,
  options: {
    defaultImageSrc:
      'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAICAgICAgICAgIDAgICAwQDAgIDBAUEBAQEBAUGBQUFBQUFBgYHBwgHBwYJCQoKCQkMDAwMDAwMDAwMDAwMDAz/2wBDAQMDAwUEBQkGBgkNCgkKDQ8ODg4ODw8MDAwMDA8PDAwMDAwMDwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8ASAP/2Q==',
  },
}

export const DEFAULT_APIS_ENDPOINTS = (version) => {
  const addPathIfNotLts = (uri) => {
    if (version.toLowerCase() === 'lts') return uri
    else {
      return version + '/realtime/'
    }
  }

  const productionSubdomain = (path) => {
    return version.toLowerCase() === 'lts' ? 'api' : 'apis'
  }

  return {
    next: {
      rest: `https://next.brocoli.io/rest/`,
      ws: addPathIfNotLts(`wss://next.brocoli.io`),
    },
    production: {
      rest: `https://${productionSubdomain(version)}.brocoli.io/rest/`,
      ws: addPathIfNotLts(`wss://${productionSubdomain(version)}.brocoli.io`),
    },
    staging: {
      rest: `https://staging.brocoli.io/rest/`,
      ws: addPathIfNotLts(`wss://staging.brocoli.io`),
    },
    development: {
      rest: `https://dev.brocoli.io/rest/`,
      ws: addPathIfNotLts(`wss://dev.brocoli.io`),
    },
  }
}
export const DEFAULT_APIS_VERSIONS = 'lts'
