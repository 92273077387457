<template>
  <nuxt-link
    class="b-link"
    :class="{
      ['b-link--minor']: minor,
      ['b-link--light']: $voicer.isLightTheme && !primary,
      ['b-link--dark']: $voicer.isDarkTheme && !primary,
      'primary--text': primary,
    }"
    :to="to"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  props: {
    minor: Boolean,
    primary: Boolean,
    to: {
      type: String,
      default: () => '/',
    },
  },
}
</script>

<style lang="scss">
a {
  text-decoration: none;
  padding-bottom: 0px;
  display: inline-block;
}

.b-link--minor.b-link--light {
  $color: rgba(175, 175, 175, 0.86);
  color: $color !important;
  border-bottom: 1px $color solid;
}
.b-link--minor.b-link--dark {
  $color: rgba(65, 65, 65, 0.86) !important;
  color: $color !important;
  border-bottom: 1px $color solid;
}
</style>
