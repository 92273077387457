<template>
  <keep-alive>
    <component :is="cModule" />
  </keep-alive>
</template>

<script>
export default {
  computed: {
    cModule() {
      // if you need old player, import './element.vue' here
      return () =>
        import(
          `@/excentrics/shells/bb-default/components/CPersistentPlayer/index.vue`
        )
    },
  },
}
</script>
