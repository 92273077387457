import DocumentAudio from '../models/brocoli/Audio'
import DocumentComment from '../models/brocoli/Comment'
import DocumentContent from '../models/brocoli/Content'
import DocumentConversation from '../models/brocoli/Conversation'
import DocumentImage from '../models/brocoli/Image'
import DocumentMedia from '../models/brocoli/Media'
import DocumentPersona from '../models/brocoli/Persona'
import DocumentSession from '../models/brocoli/Session'
import DocumentSite from '../models/brocoli/Site'
import DocumentTag from '../models/brocoli/Tag'
import DocumentUser from '../models/brocoli/User'

export default [
  DocumentAudio,
  DocumentComment,
  DocumentContent,
  DocumentConversation,
  DocumentImage,
  DocumentMedia,
  DocumentPersona,
  DocumentSession,
  DocumentSite,
  DocumentTag,
  DocumentUser,
].reduce((obj, model) => {
  return Object.assign(obj, {
    [model.modelName]: model,
  })
}, {})
