export default {
  bold: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
  },
  colorIcon: {
    type: String,
    default: undefined,
  },
  controllable: {
    type: Boolean,
    default: false,
  },
  depressed: {
    type: Boolean,
    default: true,
  },
  fab: {
    type: Boolean,
    default: false,
  },
  flat: {
    type: Boolean,
    default: false,
  },
  noAction: {
    type: Boolean,
    default: false,
  },
  redirect: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'normal',
  },
  showDuration: {
    type: Boolean,
    default: false,
  },
  useContent: {
    type: Object,
    default: null,
  },
  useEpisode: {
    type: Object,
    default: null,
  },
  useFirstEpisode: {
    type: Boolean,
    default: false,
  },
  rootReference: {
    type: String,
    default: 'content',
  },
  variant: {
    type: String,
    default: 'icon',
  },
}
