<template>
  <component :is="cModule" />
</template>

<script>
export default {
  components: {
    CModuleClassic: () => import('./classic'),
    CModuleWavy: () => import('./wavy'),
  },
  data() {
    return {
      /**
       * relative module rendered (depending config enableSplashscreen)
       */
      cModule:
        this.$voicer.getConfig('enableSplashScreen') === 'wavy' ||
        this.$voicer.getConfig('enableSplashScreen.mode') === 'wavy'
          ? 'c-module-wavy'
          : 'c-module-classic',
    }
  },
}
</script>
