<i18n>
{
  "en": {
    "login": "Login"
  },
  "fr": {
    "login": "Se connecter"
  }
}
</i18n>

<template>
  <v-app
    :dark="$voicer.isThemeDark()"
    :style="$voicer.getThemeConfigProperty('components.app.style')"
    :class="{
      'w-app': true,
      'w-app--default': true,
      [`w-app--page--${$route.name}`]: true,
      'w-app--mobile': $voicer.isMobile,
      'w-app--with-player': isPersistantVisible,
      'w-app--ios': $voicer.isIos,
      'w-app--standalone': $voicer.isStandalone,
      'w-app--not-standalone': $voicer.isStandalone === false,
      'w-app--ie': $voicer.isIE,
      'w-app--highlighted': hasHighlightedContent,
      'w-app--noscroll': noScrollbar,
    }"
  >
    <w-loader-overlay />
    <w-screen-loader />
    <w-navigation-drawer
      v-if="isAuthenticated"
      v-model="navigationDrawer"
      :navigations="navigations"
    />
    <v-toolbar
      v-bind="$voicer.getThemeConfigProperty('components.toolbar.props')"
      :color="$voicer.getThemeConfigProperty('components.toolbar.color')"
      :style="$voicer.getThemeConfigProperty('components.toolbar.style')"
      :height="$voicer.getThemeConfigProperty('components.toolbar.height')"
      :dark="$voicer.getThemeConfigProperty('components.toolbar.dark')"
    >
      <v-container class="w-toolbar__desktop-container">
        <w-block name="pre-toolbar-1" />
        <w-logo
          v-bind="
            $voicer.getThemeConfigProperty('components.toolbar-logo.props')
          "
          :style="
            $voicer.getThemeConfigProperty('components.toolbar-logo.style')
          "
        />
        <v-toolbar-title
          v-bind="
            $voicer.getThemeConfigProperty('components.toolbar-title.props')
          "
          :style="
            $voicer.getThemeConfigProperty('components.toolbar-title.style')
          "
        >
          {{ $metadatas.appName }}
        </v-toolbar-title>
        <w-block name="pre-toolbar-2" />
        <div class="w-toolbar__desktop-container__separator hidden-xs-only" />
        <v-toolbar-items
          v-if="isAuthenticated && isVisibleBottomNavigation"
          class="w-menu hidden-xs-only"
          v-bind="
            $voicer.getThemeConfigProperty('components.toolbar-items.props')
          "
          :style="
            $voicer.getThemeConfigProperty('components.toolbar-items.style')
          "
        >
          <v-btn
            v-for="(route, index) in visiblesRoutes"
            :key="route.slug"
            :color="
              isActiveRoute(route, index)
                ? $voicer.getThemeConfigProperty(
                    'components.toolbar-button.colorWhenActive'
                  )
                : $voicer.getThemeConfigProperty(
                    'components.toolbar-button.colorWhenInactive'
                  )
            "
            :style="
              $voicer.getThemeConfigProperty('components.toolbar-button.style')(
                currentModeIndex === index,
                index,
                route
              )
            "
            flat
            large
            nuxt
            @click="handleSelectRoute(route.router)"
          >
            <v-icon
              v-if="route.iconVisibleDesktop && route.icon"
              v-bind="route.iconAttrs"
              v-text="route.icon"
            />
            <v-img
              v-else-if="route.image"
              class="w-menu__item__image mr-2"
              :src="route.image.src"
              v-bind="route.image.attrs"
            />
            <span
              v-if="route.title && route.hideTitle === false"
              class="w-menu__item"
              v-text="route.title($i18n.locale)"
            />
          </v-btn>
        </v-toolbar-items>
        <v-spacer />

        <w-block name="post-toolbar-1" />
        <w-toolbar-search
          v-if="
            isAuthenticated &&
            $voicer.hasDisabledComponent('wToolbarSearch') === false
          "
        />
        <v-btn v-if="isAuthenticated" flat icon @click.stop="handleDrawer">
          <v-icon> mdi-account-box-outline </v-icon>
        </v-btn>
        <w-block name="post-toolbar-2" />
      </v-container>
    </v-toolbar>

    <w-block name="pre-heading" />
    <portal-target name="before-container" />
    <nuxt :key="$route.fullPath" />
    <w-block name="after-container" />
    <portal-target name="after-container" />
    <w-alert />
    <w-player-persistent v-if="isAuthenticated" />
    <w-ath-safari />
    <w-rgpd-modal v-if="$voicer.hasAnalyticsEnabled" />
    <w-splash-screen v-if="$voicer.getConfig('enableSplashScreen')" />
    <w-update-webapp />
    <portal-target name="after-all" />
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import handleRoutes from '~/mixins/handleRoutes'
import pageMixin from '~/mixins/page'

import WAlert from '~/components/WAlert'
import WAthSafari from '~/components/WAthSafari'
import WLoaderOverlay from '~/components/WLoaderOverlay'
import WNavigationDrawer from '~/components/WNavigationDrawer'
import WPlayerPersistent from '~/components/WPlayerPersistent'
import WRgpdModal from '~/components/WRGPDModal'
import WScreenLoader from '~/components/WScreenLoader'
import WSplashScreen from '~/components/WSplashScreen'
import WUpdateWebapp from '~/components/WUpdateWebapp'

import WToolbarSearch from '~/components/WToolbarSearch'

import ExcentricDefaultLayoutMixin from '~/excentrics/mixins/layouts/default'

export default {
  components: {
    WAlert,
    WAthSafari,
    WLoaderOverlay,
    WNavigationDrawer,
    WPlayerPersistent,
    WRgpdModal,
    WScreenLoader,
    WSplashScreen,
    WToolbarSearch,
    WUpdateWebapp,
  },

  mixins: [handleRoutes, pageMixin],

  data: () => ({
    dialogVersions: false,
    navigationDrawer: false,
    navigations: [],
  }),

  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      noScrollbar: (state) => state.root.disableAppScroll,
    }),
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },

  mounted() {
    ExcentricDefaultLayoutMixin.mounted.call(this)

    const drawerNavigation = this.$voicer.getConfig('drawerNavigation', [])
    this.navigations = drawerNavigation.filter((nav) => {
      if (typeof nav.condition !== 'function') {
        if (this.$voicer.hasAnalyticsDisabled && nav.value === '/settings') {
          return false
        }
        return true
      }

      return nav.condition(this)
    })
  },

  methods: {
    closeDrawer() {
      this.navigationDrawer = false
    },

    handleDrawer() {
      this.navigationDrawer = !this.navigationDrawer
    },
  },
}
</script>

<style lang="scss">
main.v-content {
  display: block !important;
}

.w-app--with-player {
  .application--wrap main.v-content .v-content__wrap {
    padding-bottom: 88px;
  }
}
.w-app--with-player.w-app--desktop {
  .application--wrap main.v-content .v-content__wrap {
    padding-bottom: 148px;
  }
}

.w-app--ios {
  .application--wrap main.v-content .v-content__wrap {
    padding-bottom: 172px;
  }
}
.w-app--ios.w-app--with-player {
  .application--wrap main.v-content .v-content__wrap {
    padding-bottom: 210px;
  }
}

.w-menu__item {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.w-toolbar__desktop-container {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0;
  margin: 0 auto !important;

  &__separator {
    margin-left: 42px;
    margin-right: 8px;
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
}
</style>
