var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[(_vm.isActive)?_c('div',{class:{
      'w-player-persistent': true,
      'w-player-persistent--with-bottom-nav': _vm.hasComponentBottomNav,
    },attrs:{"v-ripple":true}},[_c('w-player-progress',{attrs:{"is-live":_vm.isLive,"slider":""}}),_vm._v(" "),_c('v-card',{staticClass:"w-player-persistent__card",attrs:{"color":_vm.$voicer.getThemeConfigProperty('player.backgroundColor'),"tile":"","flat":""}},[_c('div',{staticClass:"w-player-persistent__card__controls"},[(_vm.isLive)?_c('span',{staticClass:"text-is-live red white--text caption"},[_vm._v(_vm._s(_vm.$t('live')))]):_vm._e(),_vm._v(" "),(!_vm.isLive)?_c('w-button-index',{staticClass:"hidden-xs-only w-button-high-intensity",attrs:{"orientation":"prev"}}):_vm._e(),_vm._v(" "),_c('w-button-play',{staticClass:"w-button-high-intensity",attrs:{"highlighted":false,"content":_vm.content,"props":{
            large: true,
            icon: true,
          }}}),_vm._v(" "),(!_vm.isLive)?_c('w-button-index',{staticClass:"hidden-xs-only w-button-high-intensity",attrs:{"orientation":"next"}}):_vm._e(),_vm._v(" "),(!_vm.isLive)?_c('div',{staticClass:"w-player-persistent__card__controls__timer caption grey--text"},[_c('w-player-timer',{attrs:{"type":"current"}}),_vm._v(" / "),_c('w-player-timer')],1):_vm._e()],1),_vm._v(" "),_c('v-card',{staticClass:"w-player-persistent__card__infos",attrs:{"ripple":"","flat":"","nuxt":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen.apply(null, arguments)}}},[_c('v-avatar',{attrs:{"tile":"","size":"62"}},[_c('v-img',{staticClass:"accent lighten-2",attrs:{"src":_vm.thumbnailImg.cdnSrc,"lazy-src":_vm.thumbnailImg.lazySrc,"aspect-ratio":"1","cover":""}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"accent lighten-5"}})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"w-player-persistent__card__infos__text"},[_c('div',{staticClass:"body-2"},[_vm._v("\n            "+_vm._s(_vm.content.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"caption"},[_vm._v("\n            "+_vm._s(_vm.episodeTitle)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"w-player-persistent__card__infos__actions"},[(
              _vm.reactionLikes > 0 && _vm.$voicer.enabledComponent('wButtonLike')
            )?_c('v-badge',{attrs:{"color":"red","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.reactionLikes)+"\n            ")]},proxy:true}],null,false,2751119716)},[_vm._v(" "),_c('w-button-like',{staticClass:"w-button-high-intensity",attrs:{"is-reacted":_vm.isReacted,"size":24},on:{"react":_vm.handleReact,"unreact":_vm.handleUnreact}})],1):_c('w-button-like',{staticClass:"w-button-high-intensity",attrs:{"is-reacted":_vm.isReacted,"size":32},on:{"react":_vm.handleReact,"unreact":_vm.handleUnreact}})],1)],1),_vm._v(" "),_c('div',{staticClass:"w-player-persistent__card__controls w-player-persistent__card__controls--last"},[_c('w-volume-controller'),_vm._v(" "),(
            _vm.isPageContent === false &&
            _vm.$voicer.getConfig('disablePageListen') === false
          )?_c('v-btn',{attrs:{"icon":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":_vm.$voicer
                .conditioner()
                .ifLight('grey darken-2')
                .ifDark('white')
                .render()}},[_vm._v("\n            mdi-arrow-expand\n          ")])],1):(_vm.isPageContent === true)?_c('v-btn',{attrs:{"icon":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClose.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":_vm.$voicer
                .conditioner()
                .ifLight('grey darken-2')
                .ifDark('white')
                .render()}},[_vm._v("\n            mdi-close-circle-outline\n          ")])],1):_vm._e()],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }