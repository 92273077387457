export const RESET_INITIAL_STATE = `reset initial state`
export const SET_VISIBILITY = `set visibility`
export const SET_ERROR = `set error`
export const SET_INFO = `set informative message`
export const SET_SUCCESS = `set success message`
export const SET_WARN = `set warning`

export default {
  RESET_INITIAL_STATE,
  SET_VISIBILITY,
  SET_ERROR,
  SET_INFO,
  SET_SUCCESS,
  SET_WARN,
}
