<template>
  <div
    class="b-borderline"
    :class="{
      'b-borderline--large': large,
      'b-borderline--tiny': tiny,
    }"
  >
    <v-sheet
      class="b-borderline__line"
      tile
      :color="color"
      :style="{ height }"
    ></v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: () => 'primary',
    },
    height: {
      type: String,
      default: () => '2px',
    },
    large: {
      type: Boolean,
      default: () => false,
    },
    tiny: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss">
.b-borderline {
  $c: &;

  display: flex;
  justify-content: center;

  &--large {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--tiny {
    #{$c}__line {
      width: 48px;
    }
  }
}
</style>
