/**
 * very usefull mixin, should be used when
 * you create an authenticated component to
 * get some necessaries properties as :
 * - isAuthenticated[bool]
 * - userId[string] : user id (mongo object id)
 * - name[string] : user name
 * - email[string] : user email
 * - siteName[string] : current site name
 * ```
 */
import { mapState, mapGetters } from 'vuex'

const utils = {
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
    ...mapGetters({
      email: 'auth/email',
      name: 'auth/name',
      userChars: 'auth/userChars',
      siteID: 'root/siteID',
      siteName: 'root/siteName',
      userId: 'auth/userId',
    }),
    _id() {
      if (this.id) {
        return this.id
      }
      if (this.item && this.item.id) {
        return this.item.id
      }

      return null
    },
  },
}

export default utils
