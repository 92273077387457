export default function (context) {
  const { route, store } = context
  const { mode = 'default' } = route.query

  if (route.name === 'index') {
    store.dispatch('root/setCurrentPage', mode)
  } else {
    store.dispatch('root/setCurrentPage', route.name)
  }
}
