export default {
  /**
   * @type {object}
   */
  conversation: null,
  loaders: {
    /**
     * @type {boolean}
     */
    isLoadingConversation: false,
    /**
     * @type {boolean}
     */
    isLoadingMore: false,
    /**
     * @type {boolean}
     */
    isLoadingSubmit: false,
  },
  /**
   * @type {array}
   */
  messages: [],
  /**
   * @type {array}
   */
  messagesIds: [],
}
