<template>
  <transition name="slide">
    <v-card
      v-if="isActive"
      :class="{
        'w-player-persistent': true,
        'w-player-persistent--with-bottom-nav': hasComponentBottomNav,
        'w-player-persistent--mobile': $voicer.isMobile,
      }"
      tile
      :v-ripple="true"
      @click.stop="handleOpen()"
    >
      <w-player-progress
        v-if="$vuetify.breakpoint.smAndUp"
        :is-live="isLive"
        :slider="$vuetify.breakpoint.smAndUp"
      />
      <v-card tile flat>
        <v-list :dense="$voicer.isMobile">
          <v-list-tile :class="{ 'pa-0': $voicer.isMobile }">
            <v-list-tile-avatar tile>
              <v-img
                :src="thumbnailImg.cdnSrc"
                :lazy-src="thumbnailImg.lazySrc"
                class="accent lighten-2"
                aspect-ratio="1"
                cover
              >
                <v-layout
                  slot="placeholder"
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular indeterminate color="accent lighten-5" />
                </v-layout>
              </v-img>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title class="body-2">
                {{ content.title }}
              </v-list-tile-title>
              <v-list-tile-sub-title class="caption">
                {{ episodeTitle }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <w-button-index
              v-if="!isLive"
              class="hidden-xs-only w-button-high-intensity"
              orientation="prev"
            />
            <w-button-play
              :highlighted="false"
              :content="content"
              class="w-button-high-intensity"
            />
            <w-button-index
              v-if="!isLive"
              class="hidden-xs-only w-button-high-intensity"
              orientation="next"
            />
            <w-button-like
              :is-reacted="isReacted"
              :size="24"
              class="hidden-xs-only w-button-high-intensity"
              @react="handleReact"
              @unreact="handleUnreact"
            />
            <span
              v-if="$voicer.enabledComponent('wButtonLike')"
              class="hidden-xs-only"
              >{{ reactionLikes }}</span
            >
            <w-comments
              v-if="hasCommentsSystem"
              class="hidden-xs-only w-button-high-intensity"
            />
            <span v-if="hasCommentsSystem" class="hidden-xs-only">{{
              reactionComments
            }}</span>
          </v-list-tile>
        </v-list>
      </v-card>
      <w-player-progress v-if="!isLive && $vuetify.breakpoint.xsOnly" />
    </v-card>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { $caption, $data, $metadata } from '~/utils/spoke'
import WButtonPlay from '~/components/WButtonPlay'
import WButtonIndex from '~/components/WButtonIndex'
import WButtonLike from '~/components/WButtonLike'
import WComments from '~/components/WComments'
import WPlayerProgress from '~/components/WPlayerProgress'

export default {
  components: {
    WButtonPlay,
    WButtonIndex,
    WButtonLike,
    WComments,
    WPlayerProgress,
  },

  computed: {
    ...mapGetters({
      hasCommentsSystem: 'player/hasCommentsSystem',
      isPersistantVisible: 'player/isPersistantVisible',
      isAuthenticated: 'auth/isAuthenticated',
      isVisible: 'player/isPersistantVisible',
      content: 'player/content',
      currentEpisode: 'player/currentEpisode',
    }),
    isActive() {
      return this.isAuthenticated && this.isVisible
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isReacted() {
      return $data(this.content, 'reactions.isReacted')
    },
    hasComponentBottomNav() {
      return this.$store.state.root.hasComponentBottomNav
    },
    thumbnailImg() {
      const thumbnail = $metadata(this.content, 'thumbnail')

      return thumbnail
        ? $caption(this.content, 'thumbnail')
        : $caption(this.content, 'cards:first')
    },

    title() {
      return $data(this.content, 'title')
    },
    episodeTitle() {
      const isLive = $data(this.content, 'type') === 'live'
      const defaultValue = isLive ? `Direct` : `Episode 1`

      return $metadata(this.currentEpisode, 'title', defaultValue)
    },
    isLive() {
      return this.content.type === 'live'
    },
    reactionLikes() {
      return $data(this.content, 'reactions.like')
    },
    reactionComments() {
      return $data(this.content, 'messager.stats.comments', 0)
    },
  },

  methods: {
    ...mapActions({
      react: 'contents/react',
      unreact: 'contents/unreact',
    }),

    handleOpen(e) {
      if (this.$voicer.getConfig('disablePageListen', false) === false) {
        this.$router.push({
          path: `/listen/${this.$store.state.player.content.id}`,
          query: {
            episodeIndex: this.$store.state.player.episodeIndex,
          },
        })
      }
    },

    handleReact() {
      this.react({
        content: this.content,
      })
    },

    handleUnreact() {
      this.unreact({
        content: this.content,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.w-player-persistent {
  position: fixed;
  bottom: 8px;
  right: 8px;
  left: 8px;
  z-index: 5;

  .w-button-high-intensity {
    z-index: 10;
  }

  &--with-bottom-nav {
    bottom: 64px !important;
  }

  &--mobile {
    .v-list {
      &__tile {
        padding: 0 4px;

        &__avatar {
          min-width: 46px;
        }
      }
    }
  }
}
</style>
