var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:( _obj = {
    'w-app': true,
    'w-app--default': true
  }, _obj[("w-app--page--" + (_vm.$route.name))] = true, _obj['w-app--mobile'] =  _vm.$voicer.isMobile, _obj['w-app--with-player'] =  _vm.isPersistantVisible, _obj['w-app--ios'] =  _vm.$voicer.isIos, _obj['w-app--standalone'] =  _vm.$voicer.isStandalone, _obj['w-app--not-standalone'] =  _vm.$voicer.isStandalone === false, _obj['w-app--ie'] =  _vm.$voicer.isIE, _obj['w-app--highlighted'] =  _vm.hasHighlightedContent, _obj['w-app--noscroll'] =  _vm.noScrollbar, _obj ),style:(_vm.$voicer.getThemeConfigProperty('components.app.style')),attrs:{"dark":_vm.$voicer.isThemeDark()}},[_c('w-loader-overlay'),_vm._v(" "),_c('w-screen-loader'),_vm._v(" "),(_vm.isAuthenticated)?_c('w-navigation-drawer',{attrs:{"navigations":_vm.navigations},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}}):_vm._e(),_vm._v(" "),_c('v-toolbar',_vm._b({style:(_vm.$voicer.getThemeConfigProperty('components.toolbar.style')),attrs:{"color":_vm.$voicer.getThemeConfigProperty('components.toolbar.color'),"height":_vm.$voicer.getThemeConfigProperty('components.toolbar.height'),"dark":_vm.$voicer.getThemeConfigProperty('components.toolbar.dark')}},'v-toolbar',_vm.$voicer.getThemeConfigProperty('components.toolbar.props'),false),[_c('v-container',{staticClass:"w-toolbar__desktop-container"},[_c('w-block',{attrs:{"name":"pre-toolbar-1"}}),_vm._v(" "),_c('w-logo',_vm._b({style:(_vm.$voicer.getThemeConfigProperty('components.toolbar-logo.style'))},'w-logo',
          _vm.$voicer.getThemeConfigProperty('components.toolbar-logo.props')
        ,false)),_vm._v(" "),_c('v-toolbar-title',_vm._b({style:(_vm.$voicer.getThemeConfigProperty('components.toolbar-title.style'))},'v-toolbar-title',
          _vm.$voicer.getThemeConfigProperty('components.toolbar-title.props')
        ,false),[_vm._v("\n        "+_vm._s(_vm.$metadatas.appName)+"\n      ")]),_vm._v(" "),_c('w-block',{attrs:{"name":"pre-toolbar-2"}}),_vm._v(" "),_c('div',{staticClass:"w-toolbar__desktop-container__separator hidden-xs-only"}),_vm._v(" "),(_vm.isAuthenticated && _vm.isVisibleBottomNavigation)?_c('v-toolbar-items',_vm._b({staticClass:"w-menu hidden-xs-only",style:(_vm.$voicer.getThemeConfigProperty('components.toolbar-items.style'))},'v-toolbar-items',
          _vm.$voicer.getThemeConfigProperty('components.toolbar-items.props')
        ,false),_vm._l((_vm.visiblesRoutes),function(route,index){return _c('v-btn',{key:route.slug,style:(_vm.$voicer.getThemeConfigProperty('components.toolbar-button.style')(
              _vm.currentModeIndex === index,
              index,
              route
            )),attrs:{"color":_vm.isActiveRoute(route, index)
              ? _vm.$voicer.getThemeConfigProperty(
                  'components.toolbar-button.colorWhenActive'
                )
              : _vm.$voicer.getThemeConfigProperty(
                  'components.toolbar-button.colorWhenInactive'
                ),"flat":"","large":"","nuxt":""},on:{"click":function($event){return _vm.handleSelectRoute(route.router)}}},[(route.iconVisibleDesktop && route.icon)?_c('v-icon',_vm._b({domProps:{"textContent":_vm._s(route.icon)}},'v-icon',route.iconAttrs,false)):(route.image)?_c('v-img',_vm._b({staticClass:"w-menu__item__image mr-2",attrs:{"src":route.image.src}},'v-img',route.image.attrs,false)):_vm._e(),_vm._v(" "),(route.title && route.hideTitle === false)?_c('span',{staticClass:"w-menu__item",domProps:{"textContent":_vm._s(route.title(_vm.$i18n.locale))}}):_vm._e()],1)}),1):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('w-block',{attrs:{"name":"post-toolbar-1"}}),_vm._v(" "),(
          _vm.isAuthenticated &&
          _vm.$voicer.hasDisabledComponent('wToolbarSearch') === false
        )?_c('w-toolbar-search'):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('v-btn',{attrs:{"flat":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDrawer.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-account-box-outline ")])],1):_vm._e(),_vm._v(" "),_c('w-block',{attrs:{"name":"post-toolbar-2"}})],1)],1),_vm._v(" "),_c('w-block',{attrs:{"name":"pre-heading"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"before-container"}}),_vm._v(" "),_c('nuxt',{key:_vm.$route.fullPath}),_vm._v(" "),_c('w-block',{attrs:{"name":"after-container"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"after-container"}}),_vm._v(" "),_c('w-alert'),_vm._v(" "),(_vm.isAuthenticated)?_c('w-player-persistent'):_vm._e(),_vm._v(" "),_c('w-ath-safari'),_vm._v(" "),(_vm.$voicer.hasAnalyticsEnabled)?_c('w-rgpd-modal'):_vm._e(),_vm._v(" "),(_vm.$voicer.getConfig('enableSplashScreen'))?_c('w-splash-screen'):_vm._e(),_vm._v(" "),_c('w-update-webapp'),_vm._v(" "),_c('portal-target',{attrs:{"name":"after-all"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }