export const RESET_INITIAL_STATE = `reset initial state`
export const APPEND_COMMENT = `append comment`
export const APPEND_THREAD = `append thread`
export const SET_COMMENTS_VISIBILITY = `set comments visibility`
export const SET_ACTIVE = `set active`
export const SET_LOADER = `set loader`
export const SET_PARENT_ID = `set main comment id`
export const SET_COMMENTS = `set comments list`
export const SET_OPTIONS = `set comments options`
export const SET_THREADS = `set threads list`
export const SET_WATCHING_STATUS = `set watch status`

export default {
  RESET_INITIAL_STATE,
  APPEND_THREAD,
  SET_ACTIVE,
  SET_COMMENTS_VISIBILITY,
  SET_LOADER,
  SET_OPTIONS,
  SET_PARENT_ID,
  SET_THREADS,
  SET_WATCHING_STATUS,
}
