import EventEmitter from 'eventemitter3'
import $cloneDeep from 'lodash.clonedeep'
import $get from 'lodash.get'
import $set from 'lodash.set'
import $merge from 'lodash.merge'
import $deeps from 'deeps'

import { isObject } from '../utils/check-types'
import { ConceptionError } from '../utils/error'
import routeResolve from '../utils/route-resolve'
import uidGenerator from '../utils/uid'
import Realtime from './Realtime2'
import Garbage from './Garbage'
import Authenticate from './Authenticate'

const DEFAULT_DATA = {
  id: undefined,
  metadatas: {},
  protected: {},
}

const COMMONS_FORBIDDEN_SET_PROPERTIES = ['id']

const isForbiddenProperty = (a, prop) => a.includes(prop)

class Model extends EventEmitter {
  #FORBIDDEN_SET_PROPERTIES = [...COMMONS_FORBIDDEN_SET_PROPERTIES]

  /**
   * @api private
   * @description
   * set to `true` when the first hydratation is made
   */
  #mounted = false

  /**
   * @api private
   * @description
   * original data passed to the constructor
   */
  #original = {}

  /**
   * @api private
   */
  #uid = uidGenerator()

  /**
   * @api public
   * @description
   * `true` if the document in referenced in the garbage
   */
  __garbaged = false

  /**
   * @description
   * the response object
   */
  data = {}

  /**
   * @description
   * API resource
   * Should be set during children invokation
   * @example
   * * ```js
   * super('users')
   * ```
   */
  resource = null

  get mixedData() {
    return $cloneDeep(this.data)
  }

  /**
   * @api public
   * unique identifier of this model
   */
  get __uid__() {
    return this.#uid
  }

  /**
   * @api public
   * related PseudoQuery in the garbage
   */
  get __uqid__() {
    return this.data.__uqid__
  }

  set __uqid__(uqid) {
    this.data.__uqid__ = uqid
  }

  get id() {
    return this.data.id || this.data._id
  }

  set id(id) {
    throw new Error('id can mute only during a rehydratation')
  }

  get metadatas() {
    return this.data.metadatas
  }

  get original() {
    return $cloneDeep(this.#original)
  }

  set original(original) {
    throw new Error('original cannot be updated')
  }

  get slug() {
    return this.data.slug
  }

  set slug(slug) {
    throw new Error('slug can mute only during a rehydratation')
  }

  set FORBIDDEN_SET_PROPERTIES(props) {
    this.#FORBIDDEN_SET_PROPERTIES = [
      ...this.#FORBIDDEN_SET_PROPERTIES,
      ...props,
    ]
  }

  get FORBIDDEN_SET_PROPERTIES() {
    return this.#FORBIDDEN_SET_PROPERTIES
  }

  constructor(resource, data, options) {
    super()
    this.resource = resource

    this.#original = $merge($cloneDeep(DEFAULT_DATA), data)

    this.$rehydrate(this.#original)

    Garbage.add(this)

    // todo
    // found another method to clean and destroy objects
    // maybe use the garbage ?
    Authenticate.on('unauthenticated', () => {
      this.destroy()
    })
  }

  getters() {
    return {}
  }

  $data(prop, defaultValue = null) {
    return $get(this.data, prop, defaultValue)
  }

  $metadata(slug, defaultValue) {
    return this.$data(`metadatas[${slug}]`, defaultValue)
  }

  $rehydrate(data = {}) {
    if (!this.data.__uid__) {
      this.data.__uid__ = this.__uid__
    }
    if (data && !this.id && data.id) {
      this.data.id = data.id
    }

    return this.$rehydratation(data)
  }

  /**
   * @api public
   * @param {object} data object who rehydrate the current data
   */
  $rehydratation(data = {}) {
    if (!data) {
      return null
    }
    if (data._id) {
      data.id = data._id
    }

    // enable this to support pseudo privates in models
    // seems unecessary
    //
    // data = Object.entries(Object.assign({}, data)).reduce((obj, elm) => {
    //   const [ key, value ] = elm

    //   return key.startsWith('__')
    //     ? obj
    //     : Object.assign(obj, { [ key ]: value })
    // }, {})

    const previousData = this.mixedData
    const rehydrated = $merge(this.data, data)

    const { id, slug } = previousData
    // const getters = this.getters(
    //   (prop, defaultValue) => $get(rehydrated, prop, defaultValue),
    //   this
    // )

    // need more tests to check if Object.assign
    // is suffisant
    // previous version use lodash.merge to perform
    // a deep merge of objects... bad performances
    // moreover, Object.assign allow properties drop
    // this.data = $merge(rehydrated, getters)

    // if we have a new id (only in the case where the document was empty)
    // and is effectively created (by a server call)
    // or if the slug has changed (it's possible yes)
    // __update__ is an internal event but can be used in the client side
    // to detect changes on a document even if we have stoped all changes
    if (id !== rehydrated.id || slug !== rehydrated.slug) {
      this.emit('__update__', {
        id: rehydrated.id,
        slug: rehydrated.slug,
      })
    }

    // we creating here the default objects getters
    // limitation: only first level is supported
    // Object.keys(getters).forEach((getter) => {
    //   this[getter] =
    //     typeof this.data[getter] === 'function'
    //       ? this.data[getter].call(this)
    //       : this.data[getter]
    // })

    // if #mounted is false, it's the document
    // creation (by definition, it's not an update)
    if (this.#mounted) {
      // in this case it's an update
      this.emit('updated', this.data)
    } else {
      this.emit('mounted', this.data)
      this.#mounted = true
    }

    this.#mounted = true

    return this
  }

  /**
   * @api public
   * @param {string} prop
   * @param {mixed} value
   * @description
   * Set the given property in the payload object
   */
  $set(prop, value) {
    if (isForbiddenProperty(this.#FORBIDDEN_SET_PROPERTIES, prop)) {
      throw new ConceptionError(`you cannot set the property ${prop}`)
    }

    const obj = $set({}, prop, value)
    return this.$rehydratation(obj)
  }

  clean() {
    this.data = $merge({}, $cloneDeep(DEFAULT_DATA), this.modelProperties)

    return this
  }

  destroy() {
    this.unwatch()
    this.emit('destroy')

    Object.keys(this._events).forEach((event) => {
      this.off(event)
    })

    this.clean()

    return this
  }

  /**
   * @returns {Boolean} true if current object has a valid id
   */
  exists() {
    return this.id
  }

  /**
   *
   * @param {string} pseudoId ObjectID or document slug
   */
  fetch(pseudoId) {
    if (typeof pseudoId !== 'string' && !pseudoId) {
      throw new ConceptionError('fetch require a pseudoId')
    }
    // @todo: good first issue
    // possible misconception here, if dev pass a slug as parameter
    // on the same document... ;) can be easily fix
    if (this.id && pseudoId && this.id !== pseudoId) {
      throw new ConceptionError(
        `you cannot rehydrate the current document with a different pseudoId`
      )
    }

    return this.__http
      .get(routeResolve(this.resource, pseudoId === false ? '' : pseudoId))
      .then((data) => {
        this.$rehydrate(data.item)
      })
  }

  get() {
    if (!this.id) {
      throw new ConceptionError('you cannot rehydrate an unsaved document')
    }

    return this.__http
      .get(routeResolve(this.resource, this.id))
      .then((data) => {
        this.$rehydrate(data.item)

        return data
      })
  }

  refresh() {
    return this.get()
  }

  /**
   * @api public
   * @description
   * returns a new reference object of mixedData
   */
  lean(properties) {
    // ids are mandatories and represent the smallest object
    const defaultObject = {
      __uid__: this.__uid__,
      __uqid__: this.data.__uqid__,
      id: this.data.id,
    }

    const out = $cloneDeep(defaultObject)

    // recursive object transformation (will call lean recursively)
    const applyDeepTransformation = (value) => {
      if (
        value &&
        typeof value === 'object' &&
        typeof value.lean === 'function'
      ) {
        return value.lean()
      } else if (Array.isArray(value)) {
        return [...value.map(applyDeepTransformation)]
      } else {
        return value
      }
    }

    Object.entries(this.data).forEach(([key, value]) => {
      out[key] = applyDeepTransformation(value)
    })

    if (properties === null || Array.isArray(properties)) {
      let keys = $deeps.keys(out)

      if (properties && Array.isArray(properties)) {
        // get intersection of keys ;)
        keys = keys.filter((v) => properties.includes(v))
      }

      return keys.reduce((obj, prop) => {
        const data = $get(out, prop)

        return $set(obj, prop, data)
      }, defaultObject)
    }

    return out
  }

  post(data, options = { autowatch: true }) {
    const payload = $cloneDeep(data)

    if (this.exists()) {
      throw new ConceptionError(
        'are you trying to create an existing document ?'
      )
    }

    return this.__http
      .post(routeResolve(this.resource), payload)
      .then((data) => {
        this.$rehydrate(data.item)

        // autowatch this new entry
        if (options.autowatch === true) {
          this.watch()
        }
        return this
      })
  }

  put(data, options = { autowatch: true }) {
    if (!this.exists()) {
      throw new ConceptionError('you trying to update an unexistant document')
    }

    return this.__http
      .put(routeResolve(this.resource, this.id), data)
      .then((data) => {
        this.$rehydrate(data.item)

        if (options.autowatch === true) {
          this.watch()
        }
        return this
      })
  }

  remove() {
    this.unwatch()
    return this.__http.delete(routeResolve(this.resource, this.id))
  }

  save(data, options = { autowatch: true }) {
    let req

    this.emit('beforeSave', data)

    if (!this.id) {
      req = this.post(data)
    } else {
      req = this.put(data)
    }

    return req.then((data) => {
      this.$rehydrate(data.item)
      this.emit('saved', data.item)

      if (options.autowatch === true) {
        this.watch()
      }

      return data
    })
  }

  unwatch() {
    Realtime.unwatch(`observers/updated/${this.id}/#`)

    return this
  }

  /**
   * @alias lean
   */
  toJSON(properties) {
    return this.lean(properties)
  }

  /**
   * @api public
   *
   * @description
   * Add realtime watcher for the current resource
   * Discover full documentation at https://brocoli.io/#/./realtime/observers/index
   */
  watch() {
    Realtime.watch(`observers/updated/${this.id}/#`, (payload) => {
      const { data } = payload

      this.emit('realtime_update', data)
      this.$rehydrate(data)
    })

    return this
  }

  [Symbol.toPrimitive]() {
    return this.id
  }
}

/**
 * ensure data is well formated (API-LTS compliant)
 * /!\ every API model are Objects
 * you can override this static methods in related models
 * (eg: models/commons/Audio.js)
 * @param {object} data
 * @returns {boolean}
 */
Model.isValid = function (data) {
  return isObject(data)
}

export default Model
