const path = require('path')

module.exports = {
  name: 'bb-teaser',
  version: '1',
  minVersion: 'rc8',
  routes: ['index'],
  config: {
    contentId: undefined,
    teaserDate: new Date('Oct 01, 2024 09:00:00')
  },
  configureBubblecast(config) {
    config.disablePageListen = true
    config.disableAutosetPlayerContent = true
    config.disabledComponents = ['wToolbarSearch']
    config.routes.store['contents/getContents'] = [] // disable autofetch content on index
    config.routes.store['filters/getCategories'] = []
    config.theme.components.aside.style = {
      zIndex: 1100
    }
    config.theme.components.toolbar.color = 'transparent'
    config.theme.components.toolbar.style = {
      position: 'relative',
      zIndex: 1001
    }

    config.pages = (pager) => {
      return pager
        .update('default', {
          options: {
            payload: {
              is: 'live'
            }
          }
        })
        .remove('podcasts')
        .remove('radios')
      }
  }
}
