<i18n>
{
  "en": {
    "home": "Home",
    "404": "This page does not exist",
    "404_explain": "The page you are trying to access does not exist.",
    "unknown": "Unknown error",
    "unknown_explain": "The application encountered a problem, our technical team is currently working on solving this issue."
  },
  "fr": {
    "home": "Accueil",
    "404": "Cette page n'existe pas",
    "404_explain": "La page à laquelle vous tentez d'accéder n'existe pas ou a été déplacée.",
    "unknown": "Erreur inconnue",
    "unknown_explain": "L'application a rencontré un problème, nos équipes techniques tentent de résoudre le plus rapidement possible cette erreur."
  }
}
</i18n>

<template>
  <w-layout-modal
    :class="{
      'w-page-error--mobile': $vuetify.breakpoint.smAndDown,
    }"
    class="w-page-error"
    :style="$voicer.getThemeConfigProperty('components.app.style')"
    no-title
  >
    <template #content>
      <p class="body-2">
        {{ explain }}
      </p>
      <div class="body-2 mt-2">
        <b-link to="/support" minor class="font-weight-bold">
          {{ $t('cp_footer_link_needsupport') }}
        </b-link>
      </div>
    </template>
    <template #actions>
      <v-spacer />
      <v-btn color="primary" depressed nuxt to="/">
        {{ $t('glob_home_long') }}
      </v-btn>
    </template>
  </w-layout-modal>
</template>

<script>
import ExcentricErrorLayoutMixin from '~/excentrics/mixins/layouts/error'

export default {
  components: {},
  layout: 'minimal',
  props: {
    error: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    rootClass() {
      return {
        'w-page': true,
        [`w-page--${this.error.statusCode}`]: true,
      }
    },
    title() {
      if (this.error.statusCode === 404) {
        return this.$t('page_layout_error_404_title')
      }

      return this.$t('page_layout_error_500_title')
    },

    explain() {
      if (this.error.statusCode === 404) {
        return this.$t('page_layout_error_404_text')
      }

      return this.$t('page_layout_error_500_text')
    },
  },
  mounted() {
    ExcentricErrorLayoutMixin.mounted.call(this)
  },
}
</script>

<style lang="scss" scoped>
.w-page {
  padding: 5em 0;

  h2,
  p {
    margin-bottom: 0.75em;
  }
}
</style>
