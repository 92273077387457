<template>
  <client-only>
    <w-desktop v-if="$vuetify.breakpoint.smAndUp" />
    <w-mobile v-else />
  </client-only>
</template>

<script>
import WMobile from './WMobile'
import WDesktop from './WDesktop'

export default {
  components: {
    WDesktop,
    WMobile,
  },
}
</script>
