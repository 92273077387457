/**
 * This plugin ensures that the user has a recent browser before continuing the navigation.
 * If an old browser is detected, the user is redirected to a static page /unsupported-browser
 * A better way would be achieve this redirection at the HTTP server level
 * Original issue : https://github.com/mediameeting/bubblecast/issues/479
 */
import userAgentParser from 'user-agent-parser'

// IE is explicitely forbidden
const DEFAULT_EXPLICIT_FORBIDDEN_BROWSERS = ['ie', 'iemobile']

// see https://github.com/mediameeting/bubblecast/issues/316#issuecomment-655448678
const DEFAULT_EXPLICIT_FORBIDDEN_BROWSERS_VERSIONS = [
  {
    name: 'firefox',
    minimumMajor: 68, // 9/07/2019
  },
  {
    name: 'chrome',
    minimumMajor: 50, // ~ 04/2016
  },
  {
    name: 'safari',
    minimumMajor: 11, // ~ 09/2017
  },
]

export default function (context) {
  const { app } = context
  const { userAgent } = context.$device
  const ua = userAgentParser(userAgent)

  const redirectUser = () => {
    const {
      store: {
        state: {
          i18n: { locale },
        },
      },
    } = context
    window.location.href = `/dyng/unsupported-browser-${locale}.html`
  }

  // these browsers must not be allowed to view the Bubblecast
  // (unsupported browsers, as IE)
  if (
    DEFAULT_EXPLICIT_FORBIDDEN_BROWSERS.includes(
      ua.browser.name.toLowerCase()
    ) === true
  ) {
    return redirectUser()
  }

  // user must not be allowed to view the Bubblecast
  // because of an old version of an evergreen browser
  const checkVersion = (version) => {
    const sameName = version.name === ua.browser.name.toLowerCase()
    const versionOutdated =
      parseInt(ua.browser.major, 10) < version.minimumMajor

    if (version.minimumMajor) {
      return sameName && versionOutdated
    } else {
      return sameName
    }
  }

  const forbiddenBrowsersVersions = [
    ...DEFAULT_EXPLICIT_FORBIDDEN_BROWSERS_VERSIONS,
    ...app.$voicer.getConfig('forbiddenBrowsers', []),
  ]

  if (forbiddenBrowsersVersions.some(checkVersion) === true) {
    return redirectUser()
  }
}
