<template>
  <b-label
    v-bind="$attrs"
    color="red"
    text-color="white--text"
    class="b-label-onair"
  >
    <v-icon size="14" color="white">mdi-broadcast</v-icon>
    On Air
  </b-label>
</template>

<script>
export default {}
</script>
