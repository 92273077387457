export const SET_COMPONENT_BOTTOM_NAV = 'set component bottom nav'
export const SET_PAGE = 'set root page'
export const SET_CONTROLLED_ATH_IOS = 'set controlled ath ios'
export const SET_METRICS_STATE = 'enable metrics trackers'
export const SET_SITE = 'set root site'
export const SET_INITIAL_ROUTE = 'set initial route'
export const SET_LAST_VISITED_ROUTE = 'set last visited route'
export const SET_RGPD_BOX = 'set rgpd box'
export const SET_SPLASHSCREEN_VISIBILITY = 'set splashscreen visibility'
export const SET_SPLASHSCREEN_DEJA_VU = 'set splashscreen deja vu'
export const SET_SPOKE_CONFIGURED = 'set spoke is configured'
export const SET_SPOKE_DEAD = 'set spoke is dead'
export const SET_UPDATE_WEBAPP_DIALOG_VISIBLE =
  'set update webapp dialog visible'

export default {
  SET_COMPONENT_BOTTOM_NAV,
  SET_CONTROLLED_ATH_IOS,
  SET_INITIAL_ROUTE,
  SET_LAST_VISITED_ROUTE,
  SET_PAGE,
  SET_METRICS_STATE,
  SET_RGPD_BOX,
  SET_SITE,
  SET_SPLASHSCREEN_DEJA_VU,
  SET_SPLASHSCREEN_VISIBILITY,
  SET_SPOKE_CONFIGURED,
  SET_SPOKE_DEAD,
  SET_UPDATE_WEBAPP_DIALOG_VISIBLE,
}
