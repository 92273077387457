<template>
  <a
    :href="'mailto:' + $metadatas.supportEmailAddress"
    class="w-support-email-address"
  >
    <span v-text="$metadatas.supportEmailAddress" />
    <v-icon v-if="noIcon === false" size="medium">mdi-at</v-icon>
  </a>
</template>

<script>
export default {
  props: {
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.w-support-email-address {
  border-bottom: 0;
  text-decoration: none;
}
</style>
