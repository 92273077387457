/**
 * @api public
 * @param {bool} condition
 * @param  {...any} args messages
 * @description will render a console.error if the condition is fulfilled
 */
export default function (condition, ...args) {
  if (args.length > 3) {
    // Check before the condition to catch violations early.
    throw new Error('warning() currently supports at most 3 arguments.')
  }

  if (condition) {
    const [a, b, c] = args.map((item) => '' + item)
    const message = 'Warning: '

    // We intentionally don't use spread (or .apply) because it breaks IE9:
    switch (args.length) {
      case 0:
        console.error(message)
        break
      case 1:
        console.error(message, a)
        break
      case 2:
        console.error(message, a, b)
        break
      case 3:
        console.error(message, a, b, c)
        break
    }
  }
}
