<template>
  <v-app
    class="w-app w-app--minimal"
    :dark="$voicer.isThemeDark()"
    :style="$voicer.getThemeConfigProperty('components.app.style')"
  >
    <w-loader-overlay />
    <w-screen-loader />
    <nuxt />
    <w-alert />
    <w-ath-safari />
    <w-rgpd-modal v-if="$voicer.hasAnalyticsEnabled" />
    <w-splash-screen v-if="$voicer.getConfig('enableSplashScreen')" />
    <w-update-webapp />
  </v-app>
</template>

<script>
import WAlert from '~/components/WAlert'
import WAthSafari from '~/components/WAthSafari'
import WRgpdModal from '~/components/WRGPDModal'
import WLoaderOverlay from '~/components/WLoaderOverlay'
import WScreenLoader from '~/components/WScreenLoader'
import WSplashScreen from '~/components/WSplashScreen'
import WUpdateWebapp from '~/components/WUpdateWebapp'

import ExcentricMinimalLayoutMixin from '~/excentrics/mixins/layouts/minimal'

export default {
  components: {
    WAlert,
    WAthSafari,
    WRgpdModal,
    WLoaderOverlay,
    WScreenLoader,
    WSplashScreen,
    WUpdateWebapp,
  },

  mounted() {
    ExcentricMinimalLayoutMixin.mounted.call(this)
  },
}
</script>
