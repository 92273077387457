import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// @todo(perf): load on demand
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'

const formatDate = (date, locale) => {
  return dayjs(date).locale(locale).format(`LL`)
}

const formatDateHour = (date, locale) => {
  return dayjs(date).locale(locale).format(`LLL`)
}

export default function pluginDayJS(context, inject) {
  const methods = {
    formatDate,
    formatDateHour,
  }

  dayjs.extend(localizedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)

  // @todo: customizable
  dayjs.tz.setDefault('Europe/Paris')

  inject('day', dayjs)
  inject('dayjs', methods)
}
