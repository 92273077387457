import $clone from 'lodash.clonedeep'
import $merge from 'lodash.merge'

const baseConfig = require('../../config.base')

const DEFAULT = {
  middlewares: [],
  storeActions: [],
  iconVisibleDesktop: false,
  hideTitle: false,
  visibleInMenu: true,
  router: {
    path: '/',
  },
}

class VoicerConfigPageEntry {
  constructor(entry) {
    const clonedDefault = $clone(DEFAULT)
    this.entry = $merge(clonedDefault, entry)
  }

  get hideTitle() {
    return this.entry.hideTitle
  }

  get icon() {
    return this.entry.icon
  }

  get iconAttrs() {
    return this.entry.iconAttrs
  }

  get iconVisibleDesktop() {
    return this.entry.iconVisibleDesktop
  }

  get visibleInMenu() {
    return this.entry.visibleInMenu
  }

  get payload() {
    return this.entry.options.payload
  }

  get storeActions() {
    return this.entry.storeActions
  }

  get router() {
    return this.entry.router
  }

  get name() {
    if (!this.entry.name) {
      throw new Error(
        `missing name property on a route definition: ${JSON.stringify(
          this.entry
        )}`
      )
    }
    return this.entry.name
  }

  get options() {
    return this.entry.options
  }

  title(locale) {
    if (typeof this.entry.title === 'string') {
      return this.entry.title
    } else if (this.entry.title && typeof this.entry.title === 'object') {
      return this.entry.title[locale]
    } else {
      return ''
    }
  }

  noPayload() {
    this.entry.options.payload = null

    return this
  }

  removeFilters() {
    this.entry.options.filter = false

    return this
  }

  removeHighlighted() {
    this.entry.options.highlighted = false

    return this
  }

  setInit(init) {
    $merge(this.entry.options.init, init)

    return this
  }

  setOptions(options) {
    $merge(this.entry.options, options)

    return this
  }

  setStoreActions(actions) {
    this.entry.storeActions = [...actions]

    return this
  }

  setMiddleware(middleware) {
    this.entry.middlewares.push(middleware)

    return this
  }

  setReducer(reducer) {
    this.entry.options.reducer = reducer

    return this
  }

  setRouter(router) {
    $merge(this.entry.router, router)

    return this
  }

  toObject() {
    return this.entry
  }
}

export default class VoicerConfigPages {
  constructor() {
    this.pages = baseConfig.basePages.map(
      (page) => new VoicerConfigPageEntry(page)
    )
  }

  add(entry) {
    if (typeof entry !== 'object') {
      throw new TypeError(
        `add accepts only object, you've passed type ${typeof entry}`
      )
    }

    this.pages.push(new VoicerConfigPageEntry(entry))

    return this
  }

  update(name, options) {
    this.pages = this.pages.map((page) => {
      if (page.name === name) {
        if (typeof options === 'function') {
          return options(page)
        } else {
          return new VoicerConfigPageEntry($merge(page.entry, options))
        }
      }
      return page
    })

    return this
  }

  remove(slug) {
    this.pages = this.pages.filter((page) => page.slug !== slug)

    return this
  }

  toArray() {
    return this.pages
  }
}
