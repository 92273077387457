import TYPES from './types'

function loadSpokeCollection(spoke, content) {
  const commentsCollection = spoke.collection('comments')
  commentsCollection.resource = `contents/${content.id}/conversations/${content.conversation.id}/comments`

  return commentsCollection
}

export default {
  loadConversation({ commit, rootGetters }) {
    const currentContent = rootGetters['player/content']

    // this content HAS a conversation system
    const mustLoadConversation =
      currentContent.conversation && currentContent.conversation.active === true

    if (mustLoadConversation) {
      commit(TYPES.SET_CONVERSATION, currentContent.conversation)
      commit(TYPES.SET_LOADER_IS_LOADING_CONVERSATION, true)

      const collection = loadSpokeCollection(this.$spoke, currentContent)

      collection
        .get({ reversed: true, offset: 0, limit: 150 })
        .on('success', (comments) => {
          const commentsDocuments = comments.toArray()
          commit(TYPES.SET_MESSAGES_LIST, commentsDocuments)
          commit(TYPES.SET_LOADER_IS_LOADING_CONVERSATION, false)

          this.$spoke.realtime.watch(
            `conversations/${currentContent.conversation.id}/comments`,
            (message) => {
              commit(TYPES.SET_MESSAGES_LIST_PUSH, message)
            }
          )
        })
    } else {
      commit(TYPES.RESET_INITIAL_STATE)
    }
  },

  async postMessage({ commit, rootGetters }, message) {
    const currentContent = rootGetters['player/content']
    const collection = loadSpokeCollection(this.$spoke, currentContent)
    const item = collection.fat().item()
    item.resource = collection.resource

    commit(TYPES.SET_LOADER_IS_LOADING_SUBMIT, true)
    try {
      const comment = await item.post({
        content: message,
      })

      commit(TYPES.SET_MESSAGES_LIST_PUSH, comment.lean())

      return comment
    } catch (error) {
      console.error(error)
    } finally {
      commit(TYPES.SET_LOADER_IS_LOADING_SUBMIT, false)
    }
  },
}
