export default {
  head() {
    return {
      title: this.$voicer.headTitle(this.$route, this.$store.state),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$voicer.headDescription(this.$route, this.$store.state),
        },
      ],
    }
  },
}
