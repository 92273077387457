export const RESET_INITIAL_STATE = `reset initial state`
export const SET_AUTHENTICATED = `set authenticated`
export const SET_LOADER = `set loader`
export const SET_PASSWORD_ASKED = `set password asked`
export const SET_USER = `set user`

export default {
  RESET_INITIAL_STATE,
  SET_AUTHENTICATED,
  SET_LOADER,
  SET_PASSWORD_ASKED,
  SET_USER,
}
