import AUTH_MUTATIONS_TYPES from '~/store/auth/types'
import COMMENTS_MUTATIONS_TYPES from '~/store/comments/types'
import CONTENTS_MUTATIONS_TYPES from '~/store/contents/types'
import FILTERS_MUTATIONS_TYPES from '~/store/filters/types'
import NOTIFIER_MUTATIONS_TYPES from '~/store/notifier/types'
import PLAYER_MUTATIONS_TYPES from '~/store/player/types'
import ROOT_MUTATIONS_TYPES from '~/store/root/types'

const MUTATIONS = {
  auth: AUTH_MUTATIONS_TYPES,
  comments: COMMENTS_MUTATIONS_TYPES,
  contents: CONTENTS_MUTATIONS_TYPES,
  filters: FILTERS_MUTATIONS_TYPES,
  notifier: NOTIFIER_MUTATIONS_TYPES,
  player: PLAYER_MUTATIONS_TYPES,
  root: ROOT_MUTATIONS_TYPES,
}

/**
 * @param {String} module
 * @param {Function} commit
 * @param {String} muter
 * @param {Mixed} params
 *
 * @example
 * mutate('auth')(commit)('SET_AUTHENTICATED', true)
 */
export default (moduleName) => {
  return (commit) => {
    return (muter, ...params) =>
      commit(`${moduleName}/${MUTATIONS[moduleName][muter]}`, ...params)
  }
}
