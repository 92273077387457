<template>
  <v-sheet
    class="b-common-label pa-2"
    color="transparent"
    :style="{ ...generateStyleFromColor }"
  >
    <v-avatar v-if="!!avatar" :color="color" size="36" tile>
      <v-img :src="avatar" :alt="text" />
    </v-avatar>
    <div class="font-weight-bold" :class="{ [`${color}-text`]: true }">
      {{ text }}
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: 'white',
    },
    text: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    generateStyleFromColor() {
      return {
        borderColor: `${this.color} !important`,
        color: this.color,
      }
    },
  },
}
</script>

<style lang="scss">
.b-common-label {
  border: 2px solid white !important;
  border-radius: 4px !important;
  line-height: 1 !important;
  font-size: 0.85rem;
  text-transform: uppercase;
  display: flex !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: rgba(11, 11, 11, 0.435);

  .v-avatar {
    margin-right: 8px;
  }
}
</style>
