export const APPEND_CONTENTS = `append contents`
export const IS_INSTALLED = `is installed`
export const RESET_INITIAL_STATE = `reset initial state`
export const SET_CONTENTS = `set contents`
export const SET_LOADER = `set loader`
export const UPDATE_CONTENTS = `update contents`

export default {
  APPEND_CONTENTS,
  IS_INSTALLED,
  RESET_INITIAL_STATE,
  SET_CONTENTS,
  SET_LOADER,
  UPDATE_CONTENTS,
}
