import {
  SET_VISIBILITY,
  SET_ERROR,
  SET_INFO,
  SET_SUCCESS,
  SET_WARN,
} from './types'
import { $error } from '~/utils/spoke'

const INITIAL_STATE = () => {
  return {
    /**
     * @type {String}
     * @enum info, error, success, warn
     * type of snackbar
     */
    status: 'info',

    /**
     * @type {Boolean}
     */
    isVisible: false,

    /**
     * @type {String}
     */
    message: null,
  }
}

export const state = () => ({
  ...INITIAL_STATE(),
})

export const actions = {
  /**
   * Action happening when an error occurs
   * @param {Error,String} error
   * Can be a string or an Error Object
   * @param {ErrorStack} errorStack
   * Optional parameter
   * @todo
   * Send stack error (if necessary, to logrocket...)
   */
  setError({ commit }, error, errorStack = undefined) {
    commit(SET_VISIBILITY, false)
    let message

    if (error && error instanceof Error) {
      message = $error(error)
    } else if (typeof error === 'string') {
      message = error
    } else {
      message = 'Une erreur est survenue'
    }

    try {
      if (errorStack) {
        this.$voicer.captureException(errorStack)
      } else {
        this.$voicer.captureException(error)
      }
    } catch (error) {}

    commit(SET_ERROR, message)
  },

  setInfo({ commit }, message) {
    commit(SET_VISIBILITY, false)
    commit(SET_INFO, message)
  },

  setSuccess({ commit }, message) {
    commit(SET_VISIBILITY, false)
    commit(SET_SUCCESS, message)
  },

  setWarn({ commit }, message) {
    commit(SET_VISIBILITY, false)
    commit(SET_WARN, message)
  },

  open({ state, commit }) {
    commit(SET_VISIBILITY, true)
  },

  close({ state, commit }) {
    commit(SET_VISIBILITY, false)
  },
}

export const mutations = {
  /**
   * Render an error
   */
  [SET_ERROR](state, error) {
    state.message = error
    state.status = 'error'
    state.isVisible = true
  },

  /**
   * Render a informative message
   */
  [SET_INFO](state, message) {
    state.message = message
    state.status = 'info'
    state.isVisible = true
  },

  /**
   * Render a succesfull message
   */
  [SET_SUCCESS](state, message) {
    state.message = message
    state.status = 'success'
    state.isVisible = true
  },

  /**
   * Render a warning message
   */
  [SET_WARN](state, message) {
    state.message = message
    state.status = 'warn'
    state.isVisible = true
  },

  /**
   * Display the alert message box
   * @param {Boolean} status (default `true`)
   */
  [SET_VISIBILITY](state, status = true) {
    state.isVisible = status
  },
}

export const getters = {
  isError(state) {
    return state.status === 'error'
  },
  isInfo(state) {
    return state.status === 'info'
  },
  isSuccess(state) {
    return state.status === 'success'
  },
  isWarn(state) {
    return state.status === 'warn'
  },
  message(state) {
    return state.message
  },
}
