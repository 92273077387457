<template>
  <div class="w-block__player-topbar">
    <w-logo size="18" />
    <div class="pl-1">Player {{ $metadatas.appName }}</div>
  </div>
</template>

<script>
import contentAttributesMixin from '~/excentrics/shells/bb-default/mixins/contentAttributes'

export default {
  mixins: [
    contentAttributesMixin({
      fromStore() {
        return this.$store.state.player.content
      },
    }),
  ],
}
</script>

<style lang="scss">
.w-block__player-topbar {
  display: flex;
  height: 100%;
  align-items: center;
}
</style>
