import spoke, {
  $data as _data,
  $caption as _caption,
  $metadata as _metadata,
  $protected as _protected,
} from '../@mediam/spoke-js'

export default spoke

const _error = (error, possibleErrorStack = undefined) => {
  if (possibleErrorStack) {
    console.error(possibleErrorStack)
  }

  if (!error || !(error instanceof Error)) {
    // @todo
    // log trace
    return `Une erreur inconnue est survenue`
  }

  switch (error.code) {
    case 'BAD_API_RESPONSE_AUTHORIZATION':
      return `Identifiants fournis incorrects`
    case 'BAD_API_RESPONSE_NOT_FOUND':
      return `La ressource demandée n'existe pas`
    case 'BAD_API_RESPONSE_PAYLOAD':
      return `La requête envoyée n'est pas valide`
  }

  // @todo
  // unknown error ?
  // send to logrocket
  return `Impossible de réaliser cette action pour l'instant`
}

export const $data = _data
export const $caption = _caption
export const $error = _error
export const $metadata = _metadata
export const $protected = _protected
