import EventEmitter3 from 'eventemitter3'
import $get from 'lodash.get'
import $merge from 'lodash.merge'
import { DEFAULT_CONFIGURATION } from '../defaults'
import { CoreError } from './error'

export default class Config extends EventEmitter3 {
  /**
   * @api public
   * @description the current instanced configuration
   * @see #set
   */
  configuration = Object.assign({}, DEFAULT_CONFIGURATION)

  /**
   * @description get property from the options object
   * @param {string} prop desired property
   */
  get (prop, defaultValue) {
    return $get(this.configuration, prop, defaultValue)
  }

  /**
   * @description set options
   * @param {object} options configuration object
   * @returns {config} instance
   */
  set (options) {
    this.configuration = $merge(this.configuration, options)

    if (this.configuration === null) {
      throw new CoreError('configuration cannot be null')
    }

    return this
  }
}
