export default function (context) {
  const { name, path, fullPath, params, query, hash } = context.route

  context.app.store.dispatch(
    'root/setLastVisitedRoute',
    {
      name,
      path,
      fullPath,
      params,
      query,
      hash,
    },
    { root: true }
  )
}
