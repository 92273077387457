import $merge from 'lodash.merge'

import Model from '../../services/Model'
import Realtime from '../../services/Realtime2'

/**
 * @class Content
 * @extends Model
 * @description
 * Content system model
 */
const DEFAULT_MODEL = {
  active: true,
  createdAt: Date.toString(),
  content: '',
  contents: [],
  conversations: [],
  metadatas: {
    audios: [],
    baseline: undefined,
    cards: [],
    display_date: true,
    documents: [],
    heading: '',
    links: [],
    slideshow: [],
    support_markdown: false,
    thumbnail: '',
    position: 0,
    videos: [],
    vimeos: [],
  },
  isFeatured: false,
  tags: [],
  reactions: {
    like: 0,
    isReacted: false,
  },
  slug: '',
  type: 'podcast',
  name: '',
}

export default class BaseContent extends Model {
  static modelName = 'Content'
  static modelProperties = DEFAULT_MODEL
  static resource = 'contents'

  constructor(data) {
    super('contents', $merge({}, DEFAULT_MODEL, data))
  }

  /**
   * @param {string} type (default = like)
   * @description
   * do a reaction of type `type` on a content
   * @returns {Promise}
   */
  react(type = 'like') {
    return this.__http
      .post(`/contents/${this.id}/reactions`, {
        type,
      })
      .then((data) => {
        this.$rehydratation(data.item)
        this.emit('reacted')
        Realtime.publish('event', {
          name: `reaction_${type}`,
          ref: this.id,
        })

        return data
      })
  }

  /**
   * @param {string} type (default = like)
   * @description
   * undo a reaction of type `type` on a content
   * @returns {Promise}
   */
  unreact(type = 'like') {
    return this.__http
      .delete(`/contents/${this.id}/reactions`, {
        data: { type },
      })
      .then((data) => {
        this.$rehydratation(data.item)
        this.emit('unreacted')
        Realtime.publish('event', {
          name: `unreaction_${type}`,
          ref: this.id,
        })

        return data
      })
  }
}
