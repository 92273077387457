<template>
  <v-chip
    v-if="$voicer.enabledComponent('wTagLikeCount')"
    v-bind="props"
    :text-color="textColor"
    label
    class="py-1 pl-2 text-alternate b-label w-tag-like-count"
  >
    <v-icon :size="size" :color="color" left small>mdi-thumb-up</v-icon>
    {{ likeCount }}
  </v-chip>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: Number,
      default: 14,
    },
    likeCount: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    color() {
      return this.$voicer.isDarkTheme ? 'grey darken-1' : 'grey lighten-1'
    },
    textColor() {
      return this.$voicer.isDarkTheme ? 'grey lighten-1' : 'grey darken-2'
    },
  },
}
</script>
<style lang="scss">
.w-tag-like-count {
  padding-left: 4px !important;
  padding-right: 0px;
  & > *:last-child {
    padding-right: 12px;
  }
  & > i.v-icon {
    padding-right: 0px;
  }
}
</style>
