import $merge from 'lodash.merge'

import { UnsupportedMethodError } from '../../utils/error'
import isBrowser from '../../utils/is-browser'
import warn from '../../utils/warning'
import Model from '../../services/Model'
import EventManager from '../../services/EventManager'
import Realtime from '../../services/Realtime2'

/**
 * @class BaseMedia
 * @extends Model
 * @description
 * Media system model
 */
const DEFAULT_MODEL = {
  type: '',
  value: '',
  metadatas: {},
}

export default class BaseMedia extends Model {
  static modelName = 'Media'
  static modelProperties = DEFAULT_MODEL
  static resource = 'medias'

  /**
   * @api public
   * @description
   * attached content (yes, it's a circular reference)
   */
  #content = null

  /**
   * @type {number}
   * when `getPublicURL` is called, this property will
   * be set at the current timestamp
   * a public url is available for 10 minutes
   * if getPublicURL is null or < 10m from now, a new
   * signed-url will be generated, otherwise, the
   * cached one will be set in the 'value' property
   */
  #signedAt = null

  /**
   * @type {error|null}
   * if an error happens during the "getPublicURL" async call
   * this prop will be set with the forwarded error
   */
  #signatureError = null

  /**
   * @api private
   * @type {string}
   * @description related metadata (media always depending of metadata)
   * eg: audios, documents, links, videos...
   * @doc https://brocoli.io/#/principles/metadatas/reserved
   */
  #metadata = null

  constructor(data, { content, metadata }) {
    super('medias', $merge({}, DEFAULT_MODEL, data))
    this.#content = content
    this.#metadata = metadata
  }

  /**
   * @description
   * Make an async query to fetch the current media source (signed)
   * @returns {promise}
   */
  getPublicURL() {
    if (!this.#content) {
      throw new Error('content is not set')
    }

    if (this.mustBeSigned() === false) {
      return Promise.resolve()
    }

    return this.__http
      .get(`/contents/${this.#content.id}/medias/${this.id}`)
      .then((data) => {
        const { item } = data

        this.#signedAt = new Date()
        this.#signatureError = null

        this.$rehydratation(item)
      })
      .catch((error) => {
        this.#signatureError = error
      })
  }

  /**
   * @returns {boolean} true if the current media must be signed
   */
  mustBeSigned() {
    const MUST_RESIGN = 10 * 60 * 1000 // 10 minutes, in ms
    // if signed urls are not enabled for the site
    if (this.__site.$metadata('security-signed-url', false) === false) {
      return false
    }

    // if media was never signed before, only need to be signed if no value exists
    // this allows support for old medias with public urls
    if (!this.#signedAt) {
      return !this.data.value
    }

    return new Date() - this.#signedAt > MUST_RESIGN
  }

  async $download(filename = null) {
    if (!isBrowser) {
      warn(true, '$download is not supported on non-browser environments')

      return this
    }

    try {
      await this.getPublicURL()
    } catch (error) {
      this.emit('error', error)
      return Promise.reject(error)
    }

    const node = document.createElement('a')

    if (node && node.setAttribute) {
      node.setAttribute('href', this.source)
      node.setAttribute('download', filename || this.title)
      node.setAttribute('target', '_blank')

      document.body.appendChild(node)
      node.addEventListener('click', () => {
        EventManager.emit('download_document', {
          ref: this.id,
          metadatas: this.data.metadatas,
          type: this.type,
          url: this.source,
        })
      })
      node.click()
    }

    return this
  }

  async $open(target = '_blank', ...options) {
    if (!isBrowser) {
      warn(true, '$open is not supported on non-browser environments')

      return this
    }

    try {
      await this.getPublicURL()
    } catch (error) {
      this.emit('error', error)
      return Promise.reject(error)
    }

    // eslint-disable-next-line
    const IS_URL =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gim
    const url = String(this.source)

    if (IS_URL.test(url)) {
      Realtime.publish('event', {
        name: 'click_link',
        ref: this.id,
      })
      EventManager.emit('click_link', {
        ref: this.id,
        metadatas: this.data.metadatas,
        type: this.type,
        url: this.source,
      })
      window.open(this.source, target, ...options)
    } else {
      warn(true, `${url} is not a valid URL`)
    }

    return this
  }

  exists() {
    return !!this.id
  }

  get humanType() {
    switch (this.type) {
      case 'video':
      case 'vimeo':
        return 'video'
      case 'audio':
        return 'audio'
      default:
        return this.type
    }
  }

  get position() {
    return this.$metadata('position', 0)
  }

  get publishedAt() {
    return this.$metadata(
      'publishedAt',
      this.$metadata('createdAt', new Date())
    )
  }

  get source() {
    return this.$data('value')
  }

  get title() {
    const extensions = [
      'mp3',
      'mp4',
      'jpg',
      'ogg',
      'png',
      'm4a',
      'webm',
      'pdf',
      'txt',
      'png',
      'img',
      'doc',
    ]
    const title = this.$metadata(
      'title',
      this.#metadata === 'links' ? this.source : undefined
    )

    if (!title) {
      return title
    }

    const titleExt = /(?:\.([^.]+))?$/.exec(title)

    if (titleExt.length === 2) {
      if (extensions.includes(titleExt[1])) {
        return undefined
      }
    }

    return title
  }

  get type() {
    return this.$metadata('mimetype', this.data.type)
  }

  get() {
    throw new UnsupportedMethodError('get')
  }

  delete() {
    throw new UnsupportedMethodError('delete')
  }

  post() {
    throw new UnsupportedMethodError('post')
  }

  put() {
    throw new UnsupportedMethodError('put')
  }
}
