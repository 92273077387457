<template>
  <v-img v-bind="$attrs" />
</template>

<script>
/**
 * @todo
 */
export default {}
</script>
