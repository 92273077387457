export default async (context) => {
  const { store } = context

  try {
    if (!window || !window.$workbox) {
      throw new Error('no workbow')
    }
    const workbox = await window.$workbox

    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        if (event.isUpdate) {
          setTimeout(() => {
            store.dispatch('root/setUpdateWebappDialogVisibility', true)
            // passed 10s, we're launching "update dialog"
          }, 10000)
        }
      })
    }
  } catch (err) {
    console.warn('[notice] workbox is not accessible')
  }
}
