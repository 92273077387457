<template>
  <v-btn
    v-bind="props"
    :disabled="orientation === 'next' && isLastEpisode"
    icon
  >
    <v-icon
      @click.stop="handleClick(orientation)"
      v-text="orientation === 'next' ? 'mdi-skip-next' : 'mdi-skip-previous'"
    >
      skip_next
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import spoke from '~/utils/spoke'

export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    orientation: {
      type: String,
      default: 'next',
    },
  },

  computed: {
    ...mapGetters({
      isFirstEpisode: 'player/isFirstEpisode',
      isLastEpisode: 'player/isLastEpisode',
    }),
  },

  methods: {
    /**
     * Handle the next and previous actions
     */
    handleClick(orientation) {
      this.$store.dispatch('player/ctrlPlayer', orientation)
      this.$router.replace({
        query: {
          episodeIndex: spoke.mediaplayer.episodeIndex,
        },
      })
    },
  },
}
</script>
