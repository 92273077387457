let configLocal = null

if (process.env.USE_LOCAL_CONFIG) {
  if (process.env.NODE_ENV === 'production') {
    console.warn(`⚠ Loading local configuration file in production mode`)
  }

  try {
    configLocal = require('../../config.local')
  } catch (error) {
    console.error(`Unable to load the local configuration file`)
    console.error(error)
  }
} else if (process.env.NODE_ENV === 'development') {
  console.warn(`🖐 config.local.js is ignored, use npm run dev:local`)
}

module.exports = configLocal
