import CommonSite from '../commons/Site'

export default class DocumentSite extends CommonSite {
  get name() {
    return this.$data('name')
  }

  get optAuthentication() {
    return this.$data(
      'optAuthentication',
      this.$metadata('public-can-read', false)
    )
  }

  get optRegistrable() {
    return this.$data(
      'optRegistrable',
      this.$metadata('public-can-create-account', false)
    )
  }
}
