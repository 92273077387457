import $merge from 'lodash.merge'

import { UnsupportedMethodError } from '../../utils/error'
import Model from '../../services/Model'

const DEFAULT_MODEL = {}

export default class CommonSession extends Model {
  static modelName = 'Session'
  static modelProperties = DEFAULT_MODEL
  static resource = 'sessions'

  /**
   * @api private
   * @description retrieve the current session token
   * @see token setter
   * @see token getter
   * @default null
   */
  #token = null

  constructor(data) {
    super('sessions', $merge({}, DEFAULT_MODEL, data))
  }

  get user() {
    return this.__user
  }

  get token() {
    return this.#token
  }

  set token(token) {
    this.#token = token
    this.storeToken()
  }

  delete() {
    return this.revoke()
  }

  post() {
    throw new UnsupportedMethodError('post')
  }

  clean() {
    this.token = null
    return super.clean()
  }

  async hasValidSession() {
    if (this.token === null) {
      return false
    }

    try {
      await this.__http.get(`/me`)
      return true
    } catch (error) {
      // when a session is 401 and not a jwt decode failure, user is simply unauthorized
      // (aka: unauthentified)
      // session remains valid
      if (
        error.status === 401 &&
        error.verbose !== 'SESSION_INVALID_SIGNATURE'
      ) {
        return true
      }
      return false
    }
  }

  async create() {
    // clear the token from spoke instance
    this.clean()

    const data = await this.__http.post('/sessions')
    const {
      item: { session, token: createdSessionToken },
    } = data

    this.$rehydrate(session)
    this.token = createdSessionToken

    return data
  }

  retrieveTokenFromStore() {
    const token = this.__store.get('spoke_stok')

    if (token) {
      this.token = token
    }
  }

  revoke() {
    return this.__http
      .post(`/sessions.revoke`)
      .then(() => {
        this.user.clean()
        this.token = null
      })
      .catch((error) => {
        this.user.clean()
        this.token = null
        throw new Error(`Unable to revoke session: ${error}`)
      })
  }

  storeToken() {
    if (this.token) {
      this.__http.defaults.headers.common.Authorization = `Bearer ${this.token}`
      this.__store.storeToken(this.token)

      // todo: reflexion
      // if an exception occure
      // throw UnavailableStore exception ?
    } else {
      this.__store.remove('spoke_stok')
      delete this.__http.defaults.headers.common.Authorization
    }
  }
}
