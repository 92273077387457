class Garbage {
  /**
   * @api private
   * internal store of documents references
   * stored as : uid -> { resource, document }
   * @see add()
   *
   * @todo
   * check if WeakMap has better performances
   */
  #references = new Map()

  /**
   * @api private
   * quick reference to uid to permit id/slug
   * based researchs
   */
  #hashtable = []

  /**
   * @api private
   * stack of pseudo queries
   */
  #stacks = new Map()

  /**
   * @caution
   * should never be touched (yes it's triple _)
   * only for tests purposes
   */
  get ___references() {
    return this.#references
  }

  get size() {
    return this.#references.size
  }

  /**
   * @api public
   * @param {model} document
   * store a new document in the local garbage
   * @returns {Garbage}
   */
  add(document) {
    // it should not be readd on garbage
    // maybe send a warning here (it could be a conception error)
    if (document.__garbaged) {
      return this
    }

    document.__garbaged = true

    this.#hashtable.push([
      document.__uid__,
      {
        id: document.id,
        slug: document.slug,
        collection: document.resource,
      },
    ])

    this.#references.set(document.__uid__, {
      resource: document.resource,
      document,
    })

    const findTableFromHashtable = (method = 'find') => {
      return this.#hashtable[method]((table) => {
        return table[0] === document.__uid__
      })
    }

    // watch internal documents mutations on `id` and `slug`
    // and update the hashtable in consequence
    // watch the destroy document event to remove gc references
    // on this document
    document
      .on('__update__', ({ id, slug }) => {
        const table = findTableFromHashtable()

        if (table) {
          table[1].id = id
          table[1].slug = slug
        }
      })
      .on('destroy', () => {
        const tableIndex = findTableFromHashtable('findIndex')

        if (tableIndex >= 0) {
          this.#hashtable.splice(tableIndex, 1)
          this.#references.delete(document.__uid__)
          document.__garbaged = false
        }
      })

    return this
  }

  /**
   * @api public
   * @param {PseudoQuery} pseudoQuery
   * @returns {Garbage}
   */
  createStack(pseudoQuery, document) {
    this.#stacks.set(pseudoQuery.__uqid__, pseudoQuery)

    return this
  }

  /**
   * @api public
   * @param {object} element element object (lean representation)
   * @param {string} element can be a slug or object id
   */
  find(element) {
    let result = null

    if (!element) {
      return null
    }

    if (element && typeof element === 'object' && element.__uid__) {
      if (typeof element.lean === 'function') {
        // maybe throw a warning here
        // developer pass a fat object, this function call is useless
        return element
      }

      result = this.#references.get(element.__uid__)
    }

    // if argument is a string
    if (typeof element === 'string') {
      let comparator

      if (/^[0-9a-fA-F]{24}$/i.test(element)) {
        // and length === 24 (and pass the test above)
        // we trying to find element from document ObjectID (mongo id)
        comparator = (row) => {
          return row[1].id === element
        }
      } else {
        if (!element.includes('/')) {
          // todo
          // should throw an better error
          throw new Error(
            `can't made a slug based research without the collection namespace`
          )
        }
        // otherwise, we trying to get element from slug
        comparator = (row) => row[1].slug === element
      }

      const tabledoc = this.#hashtable.find(comparator)

      if (tabledoc) {
        result = this.#references.get(tabledoc[0])
      }
    }

    if (!result) {
      // todo: conception
      // what should i do here ?
      // need more tests cases to know
      // if we should send an automatically created
      // empty document (to prevent client side error)
      // or :
      // - maybe throw an exception
      // - emit an event
      return null
    }

    return result.document
  }

  /**
   * @api public
   * @param {object} pseudoQueryObject object with a __uqid__ property
   * @returns {PseudoQuery}
   */
  findQuery(pseudoQueryObject) {
    const query = this.#stacks.get(pseudoQueryObject.__uqid__)

    // todo:
    // reflexion about not found query object

    return query || null
  }

  destroy() {
    this.#references.forEach((ref) => {
      ref.document.clean()
    })

    this.#hashtable = []
    this.#references = new Map()
    this.#stacks = new Map()

    return this
  }
}

export default new Garbage()
