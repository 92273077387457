export default {
  state: {
    forceOpenPersistentPlayerDialog: false,
    persistentPlayerDialogDefaultTab: 0,
    /**
     * @type string
     * @param classic : default mode
     * @param radio : a radio was created (see contents store)
     * @param video : todo
     * @param vimeo : todo
     */
    playerMode: 'classic',

    /**
     * @type boolean
     * @description open the radio bottom sheet if true
     */
    radioPlayerVisible: false,
  },
  actions: {
    forceOpenPersistentPlayerDialog({ commit }, { tabIndex = 0 }) {
      commit('SET_FORCE_OPEN_PERSISTENT_PLAYER_DIALOG', true)
      commit('SET_FORCE_PERSISTENT_PLAYER_DIALOG_DEFAULT_TAB', tabIndex)
    },
    closePersistentPlayerDialog({ commit }) {
      commit('SET_FORCE_OPEN_PERSISTENT_PLAYER_DIALOG', false)
      commit('SET_FORCE_PERSISTENT_PLAYER_DIALOG_DEFAULT_TAB', 0)
    },
  },
  mutations: {
    SET_FORCE_OPEN_PERSISTENT_PLAYER_DIALOG(state, value) {
      state.forceOpenPersistentPlayerDialog = value
    },
    SET_FORCE_PERSISTENT_PLAYER_DIALOG_DEFAULT_TAB(state, value) {
      state.persistentPlayerDialogDefaultTab = value
    },
    SET_PLAYER_MODE(state, mode) {
      state.playerMode = mode
    },
    SET_RADIO_PLAYER_VISIBLE(state, visible) {
      state.radioPlayerVisible = visible
    },
  },
}
