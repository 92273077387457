<script>
import Blocks from '~/blocks'

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  render(h) {
    if (this.$voicer.getConfig('enableBlocks') === false) {
      return `<!-- disabled_block: ${this.name} -->`
    }
    if (!Blocks[this.name]) {
      throw new Error(
        `Undefined block ${this.name}: check if file exists in ~/blocks`
      )
    }

    return h(Blocks[this.name].default)
  },
}
</script>
