export default {
  state: {
    /**
     * @type {boolean}
     * the conversation dialogbox is open when this value is true
     */
    conversationDialog: false,

    /**
     * @type {Boolean}
     * disable the app main scrollbar (usefull when a dialog is open)
     */
    disableAppScroll: false,
  },
  actions: {
    setAppScrollDisabled({ commit }, status) {
      commit('SET_APP_SCROLL_DISABLED', status)
    },
    setConversationDialogStatus({ commit }, status) {
      commit('SET_CONVERSATION_DIALOG_BOX', status)
    },
    commonLogic({ dispatch, rootState }) {
      // todo
      return Promise.resolve()
    },
  },
  mutations: {
    SET_APP_SCROLL_DISABLED(state, value) {
      state.disableAppScroll = value
    },
    SET_CONVERSATION_DIALOG_BOX(state, value) {
      state.conversationDialog = value
    },
  },
}
