export default function (context) {
  const { store } = context

  const { content: playerContent } = store.state.player

  // content has audio, video or vimeo files, so we're showing the persistent player
  if (playerContent.episodes.length) {
    store.dispatch('player/openPersistantPlayer')
  } else {
    store.dispatch('player/closePersistantPlayer')
  }
}
