import $get from 'lodash.get'
import {
  BAD_API_RESPONSE,
  BAD_API_RESPONSE_AUTHORIZATION,
  BAD_API_RESPONSE_DUPLICATE,
  BAD_API_RESPONSE_PAYLOAD,
  BAD_API_RESPONSE_PAYMENT,
  BAD_API_RESPONSE_NOT_FOUND,
  NO_NETWORK_CONNECTION,
} from './constants'
import BaseError from './BaseError'

export default class SpokeNetworkError extends BaseError {
  constructor(status, response) {
    super()

    let code
    let message = 'network error'

    if (status) {
      switch (status) {
        case 400:
          code = BAD_API_RESPONSE_PAYLOAD
          break
        case 401:
        case 403:
          code = BAD_API_RESPONSE_AUTHORIZATION
          break
        case 404:
          code = BAD_API_RESPONSE_NOT_FOUND
          break
        case 402:
          code = BAD_API_RESPONSE_PAYMENT
          break
        case 409:
          code = BAD_API_RESPONSE_DUPLICATE
          break
        case 422:
          code = BAD_API_RESPONSE_PAYLOAD
          break
        case 500:
        case 501:
        case 502:
        case 503:
          code = BAD_API_RESPONSE
          break
        case 520:
          code = NO_NETWORK_CONNECTION
          break
      }
    }

    this.verbose = $get(response, 'data.error', 'NO_VERBOSE')

    if (code) {
      message += '\n'
      message += `- HTTP status code ${status} : ${code} : ${this.verbose}`
      message += '\n'
      message += `- Resource URL : ${response.config && response.config.url}`
      message += '\n'
      message += `- API Response : `
      message += '\n  '
      message += JSON.stringify(response.data)
    }

    this.message = `${this.name}: ${message}`

    this.component = 'api'
    this.code = code
    this.status = status
    this.response = response
  }
}
