/**
 * message au mec qui devra un jour débugger ceci
 * excusez moi d'avoir codé cette putain de classe
 * je ne sais pas ce qui s'est passé dans ma tête
 * ta vie va devenir un enfer.
 * c'était overkill
 */
import EventEmitter from 'eventemitter3'
import $clone from 'lodash.clone'
import $merge from 'lodash.merge'

import ConceptionError from '../utils/errors/ConceptionError'
import uidGenerator from '../utils/uid'
import PseudoArray from './PseudoArray'
import Garbage from './Garbage'

/**
 * @class
 * @extends EventEmitter
 * @example
 * await spoke.collection('contents').query('earth').get().lean()
 * await spoke.collection('contents').query('earth').get().offset(5).limit(2).fat()
 *
 * @todo
 * should be documented (good first issue)
 */
class PseudoQuery extends EventEmitter {
  __uqid__ = uidGenerator()

  /**
   * @api private
   * @type {Collection}
   */
  #collection = undefined

  /**
   * @api private
   * @type {Model}
   * @type {PseudoArray}
   */
  #document = undefined

  #interceptors = []
  #query = {}
  #doLean = true
  #doLeanWithProperties = undefined
  #isExecuted = false
  #queryMany = false
  #queryOne = false
  #queryOneBySlug = false
  #queryItem = undefined
  #promise = undefined
  #mounted = false
  #rejections = []

  // todo: use Symbol
  #state = 'preload'

  constructor(collection) {
    super()
    this.#query = {}
    this.#collection = collection

    this.emit('preload')

    const events = ['prefetch', 'fetch', 'ready', 'fetched', 'error']

    events.forEach((event) => {
      this.once(event, (_) => {
        this.#state = event
      })
    })
  }

  set resource(path) {
    this.#collection.resource = path
  }

  get queryPayload() {
    return $clone(this.#query)
  }

  get meta() {
    return this.#collection.meta
  }

  get more() {
    return this.meta.more
  }

  get resource() {
    return this.#collection.resource
  }

  get state() {
    return this.#state
  }

  /**
   * @api public
   * create a new entry in the gc
   * emit a prefetch event
   * if item exists, the original document is returned
   * @todo
   * throw a conception error if this function is called
   * when the document reference contains a PseudoArray
   * @param {object} defaultValues default values passed to model
   * @param {array} properties properties passed to lean
   * @returns {Object} lean representation of the document
   */
  item(defaultValues, properties) {
    if (this.#document) {
      return this.#document
    }

    if (defaultValues && defaultValues.__uqid__) {
      this.#document = defaultValues
      return this.#document
    }

    this.emit('prefetch')

    this.#document = this.#collection.create(
      Object.assign({ __uqid__: this.__uqid__ }, defaultValues)
    )

    this.#document.on('updated', (data) => this.emit('updated', data))
    this.#document.on('destroy', (data) => this.emit('destroy', data))

    // should be placed after
    Garbage.createStack(this, this.#document)

    return this.#doLean ? this.#document.lean(properties) : this.#document
  }

  /**
   * @api public
   * @param {number} size number of items to create (default = 5)
   * @param {object} defaultValues default values passed to model
   *
   * create multiple entries based on `size` parameter
   * this methods allow developer to get a root collection (fetch a list of results)
   * if item exists, the original document is returned
   *
   * @todo
   * throw a conception error if this function is called
   * when the document reference contains a Model
   *
   * @returns {PseudoArray}
   */
  items(size = 5, defaultValues, properties) {
    if (this.#document) {
      return this.#document
    }

    this.emit('prefetch')

    this.#queryMany = true
    this.#document = new PseudoArray(
      this,
      this.#collection,
      size,
      Array.isArray(defaultValues)
        ? defaultValues.map((entryDefaultValue) => ({
            __uqid__: this.__uqid__,
            ...entryDefaultValue,
          }))
        : Object.assign({ __uqid__: this.__uqid__, defaultValues })
    )

    this.#document.on('updated', (data) => this.emit('updated', data))
    this.#document.on('destroy', (data) => this.emit('destroy', data))

    // should be placed after
    Garbage.createStack(this, this.#document)

    return this.#doLean ? this.#document.lean(properties) : this.#document
  }

  /**
   * @api public
   * @param {string} uid
   * @returns {true}
   */
  has(uid) {
    if (this.#document) {
      return this.#document instanceof PseudoArray
        ? this.#document.has(uid)
        : this.#document.__uid__ === uid
    }

    return false
  }

  /**
   * @api public
   * will serve the full representation of objects
   * - not lean for item
   * - not lean on each item for items (pseudoArray)
   *
   * @return {PseudoQuery}
   */
  fat() {
    this.#doLean = false

    return this
  }

  lean(properties) {
    this.#doLean = true
    this.#doLeanWithProperties = properties

    return this
  }

  limit(limit = 10) {
    $clone(this.queryPayload, { limit })

    return this
  }

  fetch(query) {
    return this.getOne(query)
  }

  fetchAll(query) {
    return this.get(query)
  }

  get(query) {
    const _query = $clone(query)

    this.emit('prefetch')

    let isMongoID = false
    let isSlugQuery = false

    if (typeof _query === 'string') {
      isMongoID = /^[a-f\d]{24}$/i.test(_query)
      isSlugQuery = !isMongoID
    }

    if (isMongoID || isSlugQuery) {
      this.#queryOne = true
      this.#queryOneBySlug = isSlugQuery
      this.#queryItem = _query
    }

    if (_query && typeof _query === 'object') {
      $merge(this.#query, _query)
    }

    return this.__doGet()
  }

  next(query) {
    const payload = {
      ...query,
      offset: this.#collection.meta.offset + this.#collection.meta.limit,
      limit: this.#collection.meta.limit,
    }
    return this.get(payload)
  }

  getOne(query) {
    if (this.#queryMany) {
      throw new ConceptionError('CONCEPTION_GETONE_CALL_ON_PSEUDOARRAY')
    }
    this.#queryOne = true

    return this.get(query)
  }

  query(criteria) {
    const _criteria = $clone(criteria)

    $merge(this.#query, _criteria)

    return this
  }

  offset(offset = 0) {
    $merge(this.#query, { offset })

    return this
  }

  use(interceptor) {
    this.#interceptors.push(interceptor)

    return this
  }

  // c'est surtout overkill ici
  __doGet() {
    // is wrapped in nextTick to allow
    // developer to chain methods
    this.emit('ready')
    this.emit('fetch')

    let promise

    if (this.#queryOne && this.#queryOneBySlug === false) {
      promise = this.#collection.getOne(this.#queryItem)
    } else if (this.#queryOneBySlug) {
      promise = this.#collection.get(
        $merge($clone(this.queryPayload), { slug: this.#queryItem })
      )
    } else {
      promise = this.#collection.get(this.queryPayload)
    }

    promise.then(
      async (response) => {
        this.emit('fetched', response)

        const responseCopy = await this.#interceptors.reduce(
          (promise, interceptor) => {
            return promise
              .then((response) => {
                if (typeof interceptor.then !== 'function') {
                  return Promise.resolve(interceptor(response))
                }
                return interceptor(response).then(response)
              })
              .catch((error) => {
                this.emit('error', error)
              })
          },
          Promise.resolve(response)
        )

        // if we're in this case, developer has not used the `item` or `items`
        // mechanism to prefetch contents
        // maybe send a warning to inform developer it's a bad practice...
        // in everycase, we should create item or items depending the response format
        // if the response is a simple object, it was a simple query (aka queryOne)
        // if the response is an array, it was a many query (aka queryMany)
        if (!this.#document) {
          if (Array.isArray(responseCopy)) {
            this.items(responseCopy.length, responseCopy)
          } else {
            this.item(responseCopy)
          }
        } else {
          this.#document.$rehydrate(responseCopy)
        }

        const out = this.#doLean
          ? this.#document.lean(this.#doLeanWithProperties)
          : this.#document

        if (this.#mounted === false) {
          this.#mounted = true
          this.emit('mounted', out)
        } else {
          this.emit('updated', out)
        }

        this.emit('success', out)

        return out
      },
      (error) => {
        this.emit('fetched')
        this.emit('error', error)
      }
    )

    return this
  }

  destroy() {
    this.emit('destroy')

    Object.keys(this._events).forEach((event) => {
      this.off(event)
    })

    this.#document.destroy()

    return this
  }
}

export default (collection) => new PseudoQuery(collection)
