<template>
  <v-bottom-sheet v-model="isVisible">
    <v-sheet
      flat
      tile
      color="grey lighten-4"
      class="pl-3 pt-2 pb-2 font-weight-bold grey--text text--darken-1"
    >
      <v-icon>mdi-face-agent</v-icon>
      {{ $t('cp_langswitcher_title') }}
    </v-sheet>
    <v-list two-lines>
      <v-list-tile
        v-for="(country, index) in countries"
        :key="index"
        :class="country.code === current.code ? 'grey lighten-4' : undefined"
        @click.stop="handleSelectCountry(country)"
      >
        <v-list-tile-avatar>
          <v-img :src="country.flagPath" />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title> {{ country.countryName }}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon v-if="country.code === current.code"
            >mdi-checkbox-blank-circle</v-icon
          >
          <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const countries = [...this.$i18n.locales]
      .filter((locale) => locale.enabled)
      .sort((a, b) => {
        if (a.countryName > b.countryName) return 1
        else if (a.countryName < b.countryName) return -1
        else return 0
      })
    return {
      countries,
    }
  },
  computed: {
    current() {
      return this.countries.find(
        (c) => c.code === this.$store.state.i18n.locale
      )
    },
    isVisible: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    handleSelectCountry(country) {
      try {
        this.$i18n.setLocale(country.code)
      } catch (e) {}

      this.$store.dispatch(
        'notifier/setSuccess',
        this.$t('cp_footer_lang_updated')
      )

      // this.$nuxt.refresh()
      this.$emit('close')
    },
  },
}
</script>
