export default (dispatcher) => (type, message, defaultValue) => {
  let action = 'notifier/setInfo'

  if (type === 'error') {
    action = 'notifier/setError'
  } else if (type === 'warn' || type === 'warning') {
    action = 'notifier/setWarn'
  }

  return dispatcher(action, message, { root: true })
}
