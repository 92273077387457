export const BAD_API_RESPONSE = 'BAD_API_RESPONSE'
export const BAD_API_RESPONSE_AUTHORIZATION = 'BAD_API_RESPONSE_AUTHORIZATION'
export const BAD_API_RESPONSE_DUPLICATE = 'BAD_API_RESPONSE_DUPLICATE'
export const BAD_API_RESPONSE_PAYLOAD = 'BAD_API_RESPONSE_PAYLOAD'
export const BAD_API_RESPONSE_PAYMENT = 'BAD_API_RESPONSE_PAYMENT'
export const BAD_API_RESPONSE_NOT_FOUND = 'BAD_API_RESPONSE_NOT_FOUND'
export const BAD_RT_AUTHENTICATION = 'BAD_RT_AUTHENTICATION'
export const BAD_CONFIGURATION = 'BAD_CONFIGURATION'
export const NO_NETWORK_CONNECTION = 'NO_NETWORK_CONNECTION'

export const CONCEPTION_BAD_REALTIME_HANDLER = 'CONCEPTION_BAD_REALTIME_HANDLER'
export const CONCEPTION_UNSETABLE_MODEL_GETTER =
  'CONCEPTION_UNSETABLE_MODEL_GETTER'
export const UNKNOWN_RT_RESPONSE = 'UNKNOWN_RT_RESPONSE'
