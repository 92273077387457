<template>
  <v-layout
    v-if="loading || __isLoading"
    :class="{
      'w-screen-loader--absolute': absolute,
    }"
    :style="computedStyle"
    fill-height
    align-center
    justify-center
    text-xs-center
    class="w-screen-loader white"
  >
    <div v-if="!__isDead">
      <div class="w-screen-loader__text ma-3">
        <div>
          <div class="w-screen-loader__text__title">
            <w-logo size="36" />
            <div class="ml-4">
              <div class="w-screen-loader__text__basetitle">
                {{ $metadatas.appName }}
              </div>
              <div class="w-screen-loader__text__subtitle">
                {{ $t('cp_wscreenloader_loading') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-container>
        <div class="w-screen-loader__error ma-3">
          <div class="w-screen-loader__error__title">
            <w-logo size="34" />
            <div class="ml-4">
              <div
                class="subheading mb-1"
                v-text="$t('cp_wscreenloader_appdown_title')"
              ></div>
              <div class="title">
                {{ $metadatas.appName }}
              </div>
            </div>
          </div>
          <p class="mt-2 subheading">
            {{ $t('cp_wscreenloader_appdown_description') }}
          </p>
          <div
            v-if="isRetrying"
            class="mt-2 body-2 w-screen-loader__error__retry"
          >
            <v-progress-circular
              :size="16"
              :width="2"
              indeterminate
              color="grey lighten-1"
            />
            <div class="subheading ml-2 font-weight-bold">
              {{
                $t('cp_wscreenloader_appdown_reconnectionattempt', {
                  countdown,
                })
              }}
            </div>
          </div>
          <p class="mt-3 subheading">
            <a href="/support" nuxt>{{
              $t('cp_wscreenloader_appdown_support')
            }}</a>
          </p>
        </div>
        <div class="w-screen-loader__footer ma-3">
          <b-footer no-padding />
        </div>
      </v-container>
    </div>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

const COUNTDOWN_DEFAULT = 30

export default {
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    primary: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 36,
    },
    width: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      isRetrying: false,
      countdown: COUNTDOWN_DEFAULT,
    }
  },
  computed: {
    ...mapState({
      __isLoading: (state) => state.root.isSpokeConfigured === false,
      __isDead: (state) => state.root.isSpokeDead === true,
    }),
    computedStyle() {
      return {
        backgroundColor: this.$voicer.getConfig(
          'webappThemeColor',
          this.$voicer.isThemeDark() ? '#3b3b3b' : '#fafafa'
        ),
      }
    },
  },
  watch: {
    __isDead(value) {
      if (value === true) {
        this.startRetryCountdown()
      } else {
        this.stopRetryCountdown()
      }
    },
  },
  mounted() {
    if (this.__isDead) {
      this.startRetryCountdown()
    }
  },
  methods: {
    startRetryCountdown() {
      this.isRetrying = true
      this.ctx = setInterval(() => {
        this.countdown -= 1

        if (this.countdown <= 1) {
          window.location.reload()
          this.stopRetryCountdown()
        }
      }, 1000)
    },
    stopRetryCountdown() {
      this.isRetrying = false
      clearInterval(this.ctx)
      this.countdown = COUNTDOWN_DEFAULT
    },
  },
}
</script>

<style lang="scss">
.w-screen-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;

  &--absolute {
    position: absolute;
  }

  &__text {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__basetitle {
      display: flex;
      align-items: center;
      font-size: 26px !important;
    }

    &__title {
      display: flex;
      align-items: center;
      text-align: left;
      height: 42px;
    }
  }

  &__error {
    &__title {
      display: flex;
      align-items: center;
      text-align: left;
    }

    &__retry {
      display: flex;
      align-items: center;
      text-align: left;
    }

    p {
      text-align: left;
    }
  }

  &__footer {
    text-align: left;
  }
}
</style>
