import garbager from '../../utils/garbage-manager'

import CommonTag from '../commons/Tag'

export default class DocumentTag extends CommonTag {
  $rehydratation(data) {
    if (data.tags) {
      data.tags = data.tags.map((tag) => garbager(DocumentTag, tag))
    }
    return super.$rehydratation(data)
  }
}
