import ExtendableError from 'es6-error'

export default class SpokeBaseError extends ExtendableError {
  /**
   * @api private
   * @description generate a cool message
   * @todo returns false if we are in a production environment
   */
  #getDocumentationURL() {
    if (this.code) {
      const code = `${this.code}`

      return [
        ``,
        `Note:`,
        `  📚 Take a look to the Spoke documentation: 🤓`,
        `  📚 https://spoke-js.brocoli.io/#/fr/theory/errors/${code.toLowerCase()}`,
      ].join('\n')
    }

    return [
      ``,
      `Note:`,
      `  🤪 This error seems unexplained in the Spoke documentation`,
      `  🤓 Best way to resolve this error is to check your code or ask to someone on Slack`,
    ]
  }

  toString() {
    return [this.message, this.#getDocumentationURL()]
      .filter(Boolean)
      .join('\n')
  }
}
