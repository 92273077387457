<template>
  <keep-alive>
    <component :is="renderedComponent" v-bind="{ content, ...childProps }" />
  </keep-alive>
</template>

<script>
import _mixin from './_mixin'
import specificProps from './_props'

const getGoodComponent = (type) => {
  switch (type) {
    case 'article':
      return 'PlayArticle'
    case 'audio':
      return 'PlayAudio'
    case 'video':
    case 'vimeo':
      return 'PlayVideo'
    default:
      return 'PlayAudio'
  }
}

export default {
  components: {
    PlayArticle: () => import('./PlayArticle'),
    PlayAudio: () => import('./PlayAudio'),
    PlayVideo: () => import('./PlayVideo'),
  },
  mixins: [_mixin],
  data() {
    const renderedComponent = getGoodComponent(
      this.currentContent() ? this.currentContent().type : null
    )
    const childProps = Object.keys(specificProps)
      .map((key) => key)
      .reduce((prev, key) => {
        return {
          ...prev,
          [key]: this[key],
        }
      }, {})

    return {
      renderedComponent,
      childProps,
    }
  },
}
</script>
