import TYPES from './types'
import INITIAL_STATE from './_state'

function isAlreadyPresent(messageIds, id) {
  return messageIds.includes(id)
}

export default {
  [TYPES.SET_CONVERSATION](state, conversation) {
    state.conversation = { ...conversation }
  },
  [TYPES.SET_MESSAGES_LIST](state, messages) {
    state.messages = [...messages]
    state.messagesIds = [...state.messages].map((m) => m.id)
  },
  [TYPES.SET_MESSAGES_LIST_PUSH](state, message) {
    // prevent duplication
    if (isAlreadyPresent(state.messagesIds, message.id) === false) {
      state.messages = [...state.messages, { ...message }]
      state.messagesIds = [...state.messagesIds, message.id]
    }
  },
  [TYPES.SET_LOADER_IS_LOADING_CONVERSATION](state, status) {
    state.loaders.isLoadingConversation = status
  },
  [TYPES.SET_LOADER_IS_LOADING_MORE](state, status) {
    state.loaders.isLoadingMore = status
  },
  [TYPES.SET_LOADER_IS_LOADING_SUBMIT](state, status) {
    state.loaders.isLoadingSubmit = status
  },
  [TYPES.RESET_INITIAL_STATE](state) {
    state = { ...INITIAL_STATE }
  },
}
