import $merge from 'lodash.merge'

import Model from '../../services/Model'

const DEFAULT_MODEL = {}

export default class BaseSite extends Model {
  static modelName = 'Site'
  static modelProperties = DEFAULT_MODEL

  // unecessary resource
  // http default baseURL is `apiURL/rest/2/sites/siteID`
  static resource = ''

  constructor(data) {
    super('sites', $merge({}, DEFAULT_MODEL, data))
    this.resource = ''
  }

  /**
   * @override Model.fetch()
   * site id is useless here, automatically set as baseURL
   * of axios
   */
  fetch() {
    return super.fetch('')
  }
}
