// const middlewares = {
//   getContents: {
//     before: [
//       function (context, route) {
//         // this refers to global store context
//         return Promise.resolve()
//       }
//     ],
//     after: []
//   }
// }

export default () => {
  return {
    state: {},
    actions: {},
    mutations: {},
    getters: {},
    middlewares: {},
  }
}
