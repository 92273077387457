const formatTime = {
  methods: {
    /**
     * @param {number} time time in seconds
     * @returns {string}
     * output a formated `hh:mm:ss` string according given `time`
     */
    formatTime(time) {
      if (!time) {
        return '0:00'
      }

      const hours = Math.floor(time / 3600)
      const minutes = Math.floor((time - hours * 3600) / 60)
      let seconds = Math.floor(time - hours * 3600 - minutes * 60)

      seconds = isNaN(seconds) ? 0 : seconds

      const items = [
        time >= 3600 ? hours : null,
        time >= 60 ? minutes : '0',
        seconds < 10 ? '0' + seconds : seconds,
      ]

      return items.filter((i) => i !== null).join('"')
    },
  },
}

export default formatTime
