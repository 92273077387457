import DocumentSite from '../models/brocoli/Site'

let site = null

export default function getSiteInstance() {
  if (!site) {
    site = new DocumentSite()
  }

  return site
}
