const path = require('path')

const colorPrimary = '#E31F18'

module.exports = {
  name: 'bb-multiradios',
  version: '1',
  routes: ['index'],
  config: {
    contentId: undefined
  },
  configureBubblecast(config) {
    config.disablePageListen = true
    config.disableAutosetPlayerContent = true
    config.disabledComponents = ['wToolbarSearch']
    config.routes.store['contents/getContents'] = [] // disable autofetch content on index
    config.routes.store['filters/getCategories'] = []
    config.theme.components.aside.style = {
      zIndex: 1100
    }
    config.theme.components.toolbar.color = 'transparent'
    config.theme.components.toolbar.style = {
      position: 'relative',
      zIndex: 1001
    }

    config.pages = (pager) => {
      return pager
        .update('default', {
          title: 'Radios',
          visibleInMenu: true,
          icon: 'home',
          options: {
            payload: {
              is: 'live'
            }
          }
        })
        .remove('podcasts')
        .remove('radios')
    }
  },
  nuxt() {
    return {
      styleResources: {
        scss: [path.resolve(__dirname, './assets/main.scss')]
      }
    }
  }
}
