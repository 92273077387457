<template>
  <component :is="'exc-logo'" v-bind="$attrs" />
</template>

<script>
import commons from '~/components/WLogo/commons'
import { getExcentricComponent } from '~/utils/voicer'

export default {
  components: {
    // eslint-disable-next-line
    'exc-logo': () => {
      return getExcentricComponent('logo', () =>
        import('./w-logo-native.global.vue')
      )
    },
  },
  mixins: [commons],
}
</script>
