<template>
  <svg
    enable-background="new 0 0 50 50"
    version="1.1"
    viewBox="0 0 50 50"
    :height="height"
    :width="width"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <polyline
      fill="none"
      points="17,10 25,2 33,10   "
      :stroke="color"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <line
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      x1="25"
      x2="25"
      y1="32"
      y2="2.333"
    />
    <rect fill="none" height="50" width="50" />
    <path
      d="M17,17H8v32h34V17h-9"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: () => '#000000',
    },
    size: {
      type: String,
      default: () => '50px',
    },
  },
  computed: {
    height() {
      return this.size
    },
    width() {
      return this.size
    },
  },
}
</script>
