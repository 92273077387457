import { mapActions, mapGetters } from 'vuex'
import { getConfig, getRoutes } from '~/utils/voicer'

const disableRouterActivelinkComputationBasedOnMode = getConfig(
  'disableRouterActivelinkComputationBasedOnMode',
  false
)
const redirectHomepageToMode = getConfig('redirectHomepageToMode', false)

const routes = getRoutes().filter((route) => {
  if (redirectHomepageToMode && route.slug === 'default') {
    return false
  }

  return true
})

const visiblesRoutes = routes.filter((route) => route.visibleInMenu === true)

const computeCurrentModeIndex = (currentRouteName, mode) => {
  return visiblesRoutes.findIndex((route) => {
    const { mode: routeMode = 'default' } = route.entry.router

    return currentRouteName === 'index'
      ? routeMode === mode
      : currentRouteName === route.entry.slug
  })
}

const handleRoutes = {
  data() {
    const { mode: currentMode = 'default' } = this.$route.query
    const currentModeIndex = computeCurrentModeIndex(
      this.$route.name,
      currentMode
    )

    return {
      currentModeIndex,
    }
  },
  watchQuery: true,

  watch: {
    currentPage(mode) {
      this.currentModeIndex = computeCurrentModeIndex(mode)
    },
    $route(route) {
      // Everythime a route is changed, we need to
      // reset the filter store in order to display
      // the correct content of the new page
      this.$store.dispatch('filters/resetState')
    },
  },

  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
    autoComputationBasedOnMode(route) {
      const { mode: nextMode = 'default' } = route.query || {}
      const currentModeIndex = computeCurrentModeIndex(
        this.$route.name,
        nextMode
      )

      this.setCurrentPage(nextMode)
      this.currentModeIndex = currentModeIndex
    },
    isVisibleBottomNavigation() {
      return visiblesRoutes.length > 1
    },
    routes() {
      return routes
    },
    visiblesRoutes() {
      return visiblesRoutes
    },
  },

  mounted() {
    this.$store.dispatch('root/setComponentState', {
      prop: 'hasComponentBottomNav',
      value: this.isVisibleBottomNavigation,
    })
  },

  methods: {
    ...mapActions({
      setCurrentPage: 'root/setCurrentPage',
    }),
    isActiveRoute(route, index) {
      if (
        disableRouterActivelinkComputationBasedOnMode === false &&
        (route.router.path === '/' || route.router.mode)
      ) {
        return index === this.currentModeIndex
      }

      const cpath = route.router.path

      // ugly but functional on current nuxt version
      if (route.router.path === '/') {
        return this.$route.path === '/'
      } else if (route.router.mode) {
        return this.$route.query.mode === route.router.mode
      }

      return cpath.length && this.$route.path.includes(cpath)
    },
    handleSelectRoute(router) {
      if (router && typeof router === 'object') {
        this.$router.push({
          ...router,
          query: {
            mode: router.mode,
            ...router.query,
          },
        })
      } else if (router && typeof router === 'function') {
        router(this.$router, this.$store)
      }
    },
  },
}

export default handleRoutes
