export const RESET_INITIAL_STATE = `reset initial state`
export const INIT_CATEGORIES = `init categories`
export const SET_DATE = `set date`
export const SET_INSTALLED = `set installed`
export const SET_LOADER = `set loader`
export const SET_QUERIES = `set queries`
export const SET_SELECTED = `set selected`

export default {
  RESET_INITIAL_STATE,
  INIT_CATEGORIES,
  SET_INSTALLED,
  SET_DATE,
  SET_LOADER,
  SET_QUERIES,
  SET_SELECTED,
}
