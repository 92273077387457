import { findPageFromSlug } from '~/utils/voicer'

export default function ({ route, store }) {
  const { dispatch } = store
  const routeName = route.name || 'index'

  const pageOptions = findPageFromSlug(routeName, 'options')
  const storeActions = findPageFromSlug(routeName, 'storeActions', [])

  const dispatchFuncOrArray = (fnc, pageOptions) => {
    if (typeof fnc === 'string') {
      return dispatch(fnc, pageOptions)
    } else if (Array.isArray(fnc)) {
      // [0] => function name
      // [1] => must be a function which returns parameters, contains route and store
      const dispParams = fnc[1]({ route, store })
      return dispatch(fnc[0], dispParams)
    }
  }

  const dispatchWithPromise = (nextID) => {
    const out = dispatchFuncOrArray(nextID, pageOptions)

    if (out && out.then) {
      return out
    } else {
      return Promise.resolve(out)
    }
  }

  const promisingCascade = storeActions.reduce((accumulator, nextID) => {
    return accumulator.then(() => {
      return dispatchWithPromise(nextID)
    })
  }, Promise.resolve())

  return promisingCascade
}
