<template>
  <keep-alive>
    <component
      :is="renderedComponent"
      v-bind="{ boxed, closable, conversation, visible }"
      @close="$emit('close')"
    />
  </keep-alive>
</template>

<script>
export default {
  components: {
    CModuleMobile: () => import('./mobile'),
    CModuleDesktop: () => import('./desktop'),
  },
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    conversation: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      renderedComponent: this.$voicer.isMobileOrTablet
        ? 'c-module-mobile'
        : 'c-module-desktop',
    }
  },
}
</script>
