/**
 * @utility
 * @param {array} segments
 * format a route path from segments
 * @example
 * routeResolve('hello', 'world') // hello/world
 * @return {string} formated url
 */
export default (...segments) => {
  let route = segments.filter((segment) => !!segment).join('/')

  if (route.endsWith('/')) {
    route = route.slice(0, -1)
  }

  if (route.startsWith('/')) {
    route = route.substr(1)
  }

  return route.replace(/([^:]\/)\/+/g, '/')
}
