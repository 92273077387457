<template>
  <v-bottom-sheet v-model="open" class="w-ath">
    <v-list class="w-ath-safari" three-line>
      <v-list-tile>
        <v-list-tile-avatar tile>
          <w-logo size="24" no-link :alt="$t('cp_wathsafari_altlogo')" />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="caption">
            {{ $t('cp_wathsafari_downloadapp') }}
          </v-list-tile-title>
          <v-list-tile-sub-title class="caption">
            {{ $t('cp_wathsafari_instruct1') }}
            <icon-share-ios
              size="18px"
              :color="$voicer.isDarkTheme ? 'white' : 'black'"
            />
            {{ $t('cp_wathsafari_instruct2') }}
          </v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-icon @click.stop="handleClose"> mdi-close </v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
import IconShareIos from '~/components/Icons/ShareIos'

export default {
  components: {
    IconShareIos,
  },

  data() {
    return {
      open: false,
    }
  },

  mounted() {
    const shouldBeVisible =
      this.$voicer.isIos &&
      this.$voicer.isStandalone === false &&
      this.$store.state.root.controlledAthIOS === false

    if (shouldBeVisible) {
      setTimeout(() => {
        this.open = true
      }, 4000)
    }
  },

  methods: {
    handleClose() {
      this.$store.dispatch('root/setControlledAthIOS')
      this.open = false
    },
  },
}
</script>

<style lang="scss">
.w-ath {
  z-index: 1000;
}

.w-ath-safari {
  .v-list__tile__content
    ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
    align-items: flex-start;
    padding: 8px 0;
  }
  .w-logo {
    width: 28px !important;
    height: 28px !important;
  }
}
</style>
