import Garbage from '../services/Garbage'

export default function garbager(Model, data, ...constructorOtherParams) {
  const foundGarbagedElement = Garbage.find(data.id)

  if (foundGarbagedElement) {
    return foundGarbagedElement.$rehydratation(data)
  } else {
    return new Model(data, ...constructorOtherParams)
  }
}
