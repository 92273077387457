/**
 * this file must be wrote in es2005 compliant code
 * because this shit is executed during the compilation
 */
const $clone = require('lodash.clonedeep')
const $merge = require('lodash.merge')

/**
 * Returns the defined universes (see config file)
 * @param {array} configuredCollections collections configured via the config file
 * @param {array} userCollections available collections for the current user
 * @returns {array}
 */
const getVisiblesUniverses = (
  configuredCollections = [],
  userCollections = undefined,
  context
) => {
  const getCollectionInConfig = (usrCol) => {
    return $clone(
      configuredCollections.find((c) => {
        if (c.name) {
          return c.name === usrCol.name
        } else if (c.id) {
          return c.id === usrCol.id
        } else if (c.tagId) {
          return c.tagId === usrCol.tagId
        }
        return false
      })
    )
  }

  const DEFAULT_COLLECTION_CONFIG = {
    isVisible() {
      return true
    },
    heading: {
      image: '', // todo: placeholder ?
    },
  }

  return userCollections
    .filter((userCollection) => {
      const clonedObj = getCollectionInConfig(userCollection)
      const configuredCol = $merge({}, DEFAULT_COLLECTION_CONFIG, clonedObj)

      return configuredCol.isVisible({
        userCollections,
        collection: configuredCol,
        context,
      })
    })
    .map((userCollection) => {
      const configuredCol = $merge(
        $clone(DEFAULT_COLLECTION_CONFIG),
        getCollectionInConfig(userCollection)
      )

      return {
        ...userCollection,
        config: configuredCol,
      }
    })
}

/**
 *
 */
const getUniverseFromId = (universes, id) => {
  return universes.find((universe) => {
    const simpleCheck = universe.id === id || universe.tagId === id

    if (Array.isArray(universe.tagId) === true) {
      return universe.tagId.join(',') === id
    }

    return simpleCheck
  })
}

module.exports = {
  getVisiblesUniverses,
  getUniverseFromId,
}
