<template>
  <v-snackbar
    v-model="visible"
    :multi-line="$voicer.isMobile"
    top
    :timeout="0"
    color="primary"
  >
    <div class="body-2">
      {{
        isUpdating === false
          ? $t('cp_updatewebapp_title')
          : $t('cp_updatewebapp_updating')
      }}
    </div>
    <div>
      <v-btn v-if="isUpdating === false" flat @click.stop="handleUpdate">
        {{ $t('cp_updatewebapp_button') }}
      </v-btn>
      <v-progress-circular v-else indeterminate color="white" />
    </div>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      isUpdating: false,
      visible: this.$store.state.root.updateWebappDialog,
    }
  },
  watch: {
    '$store.state.root.updateWebappDialog'(v) {
      this.visible = v
    },
  },
  methods: {
    handleUpdate() {
      this.isUpdating = true
      setTimeout(() => {
        window.location.reload()
      }, 1200)
    },
  },
}
</script>
