<template>
  <v-card
    :elevation="flat ? 0 : 1"
    class="b-label pl-2 pr-2 pt-1 pb-1 text-alternate"
    :class="{
      'font-weight-bold': bold,
      'b-label--small': small,
      'b-label--x-small': xSmall,
      [color]: true,
      [textColor]: true,
    }"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'grey lighten-3',
    },
    textColor: {
      type: String,
      default: 'grey--text text--darken-2',
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.b-label {
  border-radius: 2px;
  font-size: 11px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  max-height: 32px;
  min-width: 42px;
  text-align: center;
  justify-content: center;

  & > * {
    padding-right: 4px;
  }

  & > *:last-child {
    padding-right: 0;
  }

  & > i.v-icon {
    padding-right: 4px;
  }

  &--small {
    padding: 3px 6px !important;
    font-size: 10px;
    font-weight: normal !important;
  }

  &--x-small {
    padding: 2px 3px !important;
    font-size: 9px;
    font-weight: normal !important;
  }
}
</style>
