<template>
  <v-card class="b-common-media-card" :style="{ ...borderColorPrimary }">
    <v-img
      :gradient="$voicer.getImageGradient()"
      :aspect-ratio="
        $voicer.getThemeConfigProperty('component.card.aspectRatio', 800 / 390)
      "
      src="https://cdn.vuetifyjs.com/images/cards/desert.jpg"
      class="b-common-media-card__background-figure"
    ></v-img>
    <div class="b-common-media-card__inner-labels pa-4">
      <b-common-label
        avatar="https://picsum.photos/id/237/42/42"
        text="Collection 1"
      />
    </div>
    <v-card
      class="b-common-media-card__content-card white pa-4"
      elevation="4"
      :style="{ ...borderColorPrimary }"
    >
      <div>
        <div class="b-common-media-card__content-card__labels mb-3">
          <b-common-label color="black" text="podcast" />
        </div>
        <div
          class="b-common-media-card__content-card__title headline font-weight-bold font-alt"
        >
          Titre de ce contenu, idéalement environ 60 caractères pour ne pas
          faire vide
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import BCommonLabel from '../BCommonLabel'
import { styles } from '../mixins'

export default {
  components: {
    BCommonLabel,
  },
  mixins: [styles],
}
</script>

<style lang="scss">
.b-common-media-card {
  $cardTitleHeight: 160px;

  position: relative;
  margin-bottom: $cardTitleHeight / 2;
  border-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  &__background-figure {
    border-radius: 12px !important;
  }

  &__inner-labels {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__content-card {
    $height: $cardTitleHeight;

    position: absolute !important;
    bottom: $height / 2 * -1;
    left: 50px;
    right: 50px;
    border-radius: 12px !important;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__labels {
      display: flex;
      align-items: flex-start;
    }

    &__title {
      max-width: 840px;
    }
  }
}
</style>
