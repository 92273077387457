<template>
  <keep-alive>
    <component :is="renderedComponent" v-bind="{ isLive, slider }" />
  </keep-alive>
</template>

<script>
export default {
  components: {
    WSlider: () => import('./WSlider'),
    WProgress: () => import('./WProgress'),
  },
  props: {
    isLive: {
      type: Boolean,
      default: false,
    },
    slider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let renderedComponent = 'w-slider'

    if (this.slider === false) {
      renderedComponent = 'w-progress'
    }

    return { renderedComponent }
  },
}
</script>
