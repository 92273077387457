import localstore from 'store'

import notifier from '~/utils/notifier'

/**
 * privacy middleware
 * @description
 * ce middleware s'assurera du respect de la vie privée des utilisateurs.
 * @vip https://github.com/mediameeting/bubblecast/issues/277
 */
export default (context) => {
  if (process.client) {
    const notify = notifier(context.store.dispatch)
    const acceptedRGPDBox = localstore.get('acceptedRGPD', false)
    const rejectedRGPDBox = localstore.get('rejectedRGPD', false)

    if (navigator && navigator.doNotTrack === '1') {
      // client has do not track activated
      // set acceptedRGPDBox: true
      // set optPrivacyMetrics: false
      if (acceptedRGPDBox === false) {
        context.store.dispatch('root/disableMetricsTrackers')
        const notifiedUser = localstore.get('DNTNotified', false)

        if (notifiedUser === false) {
          notify(
            'success',
            'La collecte des données a été désactivée par les préférences de votre navigateur'
          )
          localstore.set('DNTNotified', true)
        }
      }
    } else if (acceptedRGPDBox) {
      // client has accepted RGPD
      context.store.dispatch('root/enableMetricsTrackers')
    } else if (rejectedRGPDBox) {
      // client has rejected RGPD
      context.store.dispatch('root/disableMetricsTrackers')
    }
  }
}
