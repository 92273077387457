import EventEmitter3 from 'eventemitter3'
import isBrowser from './is-browser'

/**
 * @todo support node 🤪
 * in a node environment, you'll need to send
 * periodically some events to a well-known
 * DNS... yes, it's disgusting 🤬
 */
class NetworkDetection extends EventEmitter3 {
  constructor() {
    super()

    if (isBrowser) {
      window.addEventListener('online', (_) => {
        this.emit('online')
        this.emit('change', false)
      })
      window.addEventListener('offline', (_) => {
        this.emit('offline')
        this.emit('change', true)
      })
    }
  }

  get status() {
    if (isBrowser) {
      return navigator.onLine
    }

    // currently node is not supported
    // we presume the process has an active network connection
    return true
  }
}

export default new NetworkDetection()
