import $merge from 'lodash.merge'

import Model from '../../services/Model'
import { UnsupportedMethodError } from '../../utils/error'

/**
 * @class Persona
 * @extends Model
 * @description
 * Persona model
 */
const DEFAULT_MODEL = {}

export default class CommonPersona extends Model {
  static modelName = 'Persona'
  static modelProperties = DEFAULT_MODEL
  static resource = 'personaes'

  constructor(data) {
    super('personaes', $merge({}, DEFAULT_MODEL, data))
  }

  get name() {
    return this.$metadata('name')
  }

  get() {
    throw new UnsupportedMethodError('get')
  }

  post() {
    throw new UnsupportedMethodError('post')
  }

  put() {
    throw new UnsupportedMethodError('put')
  }

  delete() {
    throw new UnsupportedMethodError('delete')
  }
}
