import EventEmitter from 'eventemitter3'

import nextTick from '../utils/next-tick'
import ConceptionError from '../utils/errors/ConceptionError'
import Garbage from './Garbage'
import MediaPlayer from './MediaPlayer'
import Realtime from './Realtime2'

/**
 * @api public
 * @class
 * Authenticate
 *
 * @description
 * This service provide some usefull methods to help clients
 * to interact with the authentication system
 */
class Authenticate extends EventEmitter {
  /**
   * @alias User.isAuthenticated
   * @returns {boolean} true if user is well authenticated
   */
  get isAuthenticated() {
    return this.__user.isAuthenticated
  }

  get user() {
    return this.__user
  }

  /**
   * @api public
   * @param {object}
   *  @prop {string} login
   *  @prop {string} password
   * @description
   * If login / password :
   *  Make a /authenticate API call with the given credentials
   * If token
   *  Make a /me API call with the given token
   */
  async login(data, skipPost = false) {
    let user

    if (skipPost === true) {
      user = data
    } else {
      const credentials = {
        login: data.login,
        password: data.password,
      }

      if ((await this.user.session.hasValidSession()) === false) {
        await this.user.session.create()
      }

      // prevent some weirds issues on the
      // next login when an user has a
      // missing token stored in localstorage
      this.user.session.storeToken()

      const response = await this.__http.post('/authenticate', credentials)

      if (!response) {
        throw new Error(
          'api error - the api response has returned a null object'
        )
      }
      if (typeof response !== 'object' || typeof response.item !== 'object') {
        throw new TypeError('api error - the api response is misformated')
      }

      user = response.item.user
    }

    this.user.$rehydrate(user)
    this.user.isAuthenticated = true

    this.emit('authenticated')
    this.user.emit('authenticated')
    return user
  }

  /**
   * @api public
   * @description
   * Simulate `authenticated` state on `optAuthentication` = false
   * site.
   * Needed if the bubblecast is public
   * @returns {Authenticate}
   */
  async loginOnPublicSite() {
    if ((await this.user.session.hasValidSession()) === false) {
      await this.user.session.create()
    }

    this.user.session.storeToken()
    this.user.$rehydrate({
      sid: this.user.session.id,
      isShallowUser: true,
    })

    this.emit('authenticated')
    this.user.emit('authenticated')

    return this
  }

  /**
   * @api public
   * @description
   * Unset the bearer token and user data
   * @returns {Authenticate}
   */
  async logout(force = false) {
    MediaPlayer.stop()
    await this.user.session.revoke()
    this.user.clean()
    Garbage.destroy()

    this.emit('unauthenticated')
    this.user.emit('unauthenticated')

    Realtime.end()
    if (force === false) {
      // same reason here
      // nextTick is necessary to prevent some mqttjs
      // misconceptions (fuck it)
      await nextTick(() => {
        return this.user.session.create()
      })
    }
    return this
  }

  /**
   * Asks brocoli for a new password: will send a mail/sms to the user if it exists
   * @param {*} login
   */
  askNewPassword(login) {
    if (!login) {
      throw new ConceptionError('missing login')
    }

    return this.__http.post(`/users/password_ask`, {
      login,
    })
  }

  /**
   * Sets a new password for a user
   * @param {*} login
   */
  resetPassword(userId, code, password) {
    if (!userId || !code || !password) {
      throw new ConceptionError('missing parameter')
    }

    return this.__http.post(`/users/${userId}/password_reset`, {
      code,
      password,
    })
  }
}

export default new Authenticate()
