import {
  INIT_CATEGORIES,
  RESET_INITIAL_STATE,
  SET_INSTALLED,
  SET_SELECTED,
  SET_QUERIES,
  SET_DATE,
} from './types'
import spoke from '~/utils/spoke'

const INITIAL_STATE = {
  isInstalled: false,
  categories: [],
  selected: {},
  query: {
    month: undefined,
    year: undefined,
    q: [],
  },
}

export const state = () => ({
  ...INITIAL_STATE,
})

export const actions = {
  /**
   * Get all the tags within categories
   */
  getCategories({ commit, state }) {
    if (state.isInstalled === true) {
      return
    }

    const pseudoQuery = spoke.collection('tags')

    const query = {
      withConversations: false,
      withMedias: false,
      withMetadatas: false,
      withTags: false,
    }

    pseudoQuery.get(query).on('success', (tags) => {
      commit(SET_INSTALLED, true)
      commit(INIT_CATEGORIES, tags.toArray())
    })
  },

  /**
   * Set in state.q the initial queries according the
   * current req.query parameters
   * Set from nuxtServerInit method
   * @param {Array} queries
   */
  setInitialQueries({ commit }, queries) {
    commit(SET_QUERIES, queries)
  },

  /**
   * Save the query parameters for & behavior in filters
   * Anytime we save a new query param, we assume the user
   * wants to get new content, so we dispatch getContent
   * Will rehydrate contents with the new query
   * @param {Object} payload
   */
  saveTagQuery({ state, commit, dispatch }, payload) {
    commit(SET_SELECTED, payload)

    // Concat all tags in same store variable
    const tags = Object.keys(state.selected).reduce((prev, key) => {
      return [...prev, ...state.selected[key]]
    }, [])

    commit(SET_QUERIES, tags)
  },

  /**
   * Save the date as query parameter
   * Will rehydrate contents with the new query
   * @param {Date} date
   */
  saveDateQuery({ state, commit, dispatch }, date) {
    commit(SET_DATE, date)
  },

  resetState({ commit }) {
    commit(RESET_INITIAL_STATE)
  },
}

export const mutations = {
  /**
   * Indicate filters are installed (will skip every new requests)
   * @param {Boolean} status
   */
  [SET_INSTALLED](state, status = true) {
    state.isInstalled = status
  },

  /**
   * Set the categories for filter components
   * and fill the store to prepare tags selected
   * @param {Array} categories
   */
  [INIT_CATEGORIES](state, categories) {
    state.categories = categories
    categories.forEach((cat) => {
      state.selected[cat.slug] = []
    })
  },

  /**
   * Set the year and month sent with new query
   * @param {Date} date
   */
  [SET_DATE](state, date) {
    state.query = { ...state.query, ...date }
  },

  /**
   * Set the query sent whenever we filter podcasts
   */
  [SET_QUERIES](state, queries) {
    state.query = {
      ...state.query,
      q: queries,
    }
  },

  /**
   * Set the selected tags before requesting new content
   * @param {Object} payload
   * @param {String} payload.category
   * @param {Array} payload.tags
   */
  [SET_SELECTED](state, payload) {
    state.selected[payload.category] = payload.tags
  },

  /**
   * reset vuex filters store on initial state
   */
  [RESET_INITIAL_STATE](state) {
    state.query = { ...INITIAL_STATE.query }
  },
}

export const getters = {}
