const SET_CONVERSATION = 'MESSAGES:SET_CONVERSATION'
const SET_MESSAGES_LIST = 'MESSAGES:SET_MESSAGES_LIST'
const SET_MESSAGES_LIST_PUSH = 'MESSAGES:SET_MESSAGES_LIST_PUSH'
const SET_LOADER_IS_LOADING_CONVERSATION =
  'MESSAGES:SET_LOADER_IS_LOADING_CONVERSATION'
const SET_LOADER_IS_LOADING_MORE = 'MESSAGES:SET_LOADER_IS_LOADING_MORE'
const SET_LOADER_IS_LOADING_SUBMIT = 'MESSAGES:SET_LOADER_IS_LOADING_SUBMIT'
const RESET_INITIAL_STATE = 'MESSAGES:RESET_INITIAL_STATE'

export default {
  SET_CONVERSATION,
  SET_MESSAGES_LIST,
  SET_MESSAGES_LIST_PUSH,
  SET_LOADER_IS_LOADING_CONVERSATION,
  SET_LOADER_IS_LOADING_MORE,
  SET_LOADER_IS_LOADING_SUBMIT,
  RESET_INITIAL_STATE,
}
