<template>
  <div
    class="w-player-timer caption font-weight-bold grey--text"
    :class="{
      'text--lighten-1': type === 'total',
      'text--darken-1': type === 'current',
    }"
    v-text="value"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import spoke, { $metadata } from '~/utils/spoke'
import formatTime from '~/mixins/formatTime'

export default {
  mixins: [formatTime],

  props: {
    type: {
      type: String,
      default: 'total', // or current
    },
  },

  data: () => ({
    time: 0,
  }),

  computed: {
    ...mapGetters({
      episode: 'player/currentEpisode',
    }),
    duration() {
      return $metadata(this.episode, 'duration', 0)
    },
    value() {
      return this.formatTime(
        this.type === 'current' ? this.time : this.duration
      )
    },
  },

  mounted() {
    if (this.type === 'current') {
      spoke.mediaplayer.on('end', () => {
        this.time = 0
      })
      spoke.mediaplayer.on('episode_updated', () => {
        this.time = 0
      })
      spoke.mediaplayer.on('timeupdate', ({ data: { time } }) => {
        this.time = time
      })
    }
  },
}
</script>
