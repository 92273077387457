const { config } = require('~/utils/voicer')

const commonsBlocks = {
  'after-container': require('./after-container.vue'),
  cta: require('./cta.vue'),
  footer: require('./footer.vue'),
  heading: require('./heading.vue'),
  'player-topbar': require('./player-topbar.vue'),
  'pre-featured': require('./pre-featured.vue'),
  'pre-heading': require('./pre-heading.vue'),
  'pre-toolbar-1': require('./pre-toolbar-1.vue'),
  'pre-toolbar-2': require('./pre-toolbar-2.vue'),
  'post-featured': require('./post-featured.vue'),
  'post-toolbar-1': require('./post-toolbar-1.vue'),
  'post-toolbar-2': require('./post-toolbar-2.vue'),
  prelogin: require('./prelogin.vue'),
  splashscreen: require('./splashscreen.vue'),
}

if (config.bubbleshell && config.bubbleshell.blocks) {
  module.exports = {
    ...commonsBlocks,
    ...(typeof config.bubbleshell.blocks === 'function'
      ? config.bubbleshell.blocks()
      : config.bubbleshell.blocks),
  }
} else {
  module.exports = commonsBlocks
}
