<template>
  <div
    class="b-section-subtitle"
    :class="{ 'b-section-subtitle--large': large }"
  >
    <div class="b-section-subtitle__wrapper mt-1">
      <div
        class="text-alternate caption font-weight-light grey--text text--lighten-1"
      >
        <slot />
        <div class="borderlines">
          <b-borderline color="grey lighten-2" height="3px" />
          <b-borderline color="grey lighten-2" height="3px" />
          <b-borderline color="grey lighten-2" height="3px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
