const path = require('path')

module.exports = {
  name: 'bb-vimeo',
  version: '1',
  minVersion: 'rc8',
  routes: ['listen-content'],
  configureBubblecast(config, shellConfig) {
    config.disableAutoplay = true
    config.theme.components.aside.style = {
      zIndex: 1100
    }
    config.theme.components.toolbar.color = 'transparent'
    config.theme.components.toolbar.style = {
      position: 'relative',
      zIndex: 1001
    }

    config.pages = (pager) => {
      return pager
        .update('default', {
          options: {
            payload: {
              is: 'podcast'
            }
          }
        })
        .remove('podcasts')
        .remove('radios')
      }
  }
}
