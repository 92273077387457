<template>
  <div class="w-volume-controller">
    <v-btn icon @click="setupVolume = true">
      <v-icon color="grey lighten-1"> mdi-volume-medium </v-icon>
    </v-btn>
    <v-dialog v-model="setupVolume" offset-y width="380" max-width="100%">
      <v-card class="w-volume-controller__popover">
        <v-card-title class="headline">{{
          $t('cmp_cpersistentplayer_wvolumecontroller_popup_title')
        }}</v-card-title>
        <div class="pa-5">
          <div class="w-volume-controller__popopover_btns"></div>
          <vue-slider
            v-model="volume"
            class="w-volume-controller__slider"
            :height="3"
            :duration="0.1"
            :tooltip-formatter="formatter"
            :dot-style="{
              backgroundColor: $metadatas.colorPrimary,
            }"
            :dot-size="18"
            :rail-style="{
              backgroundColor: '#dedede',
            }"
            :process-style="{
              backgroundColor: $metadatas.colorPrimary,
            }"
            :step-style="{
              backgroundColor: $metadatas.colorPrimary,
            }"
            :tooltip-style="{
              backgroundColor: $metadatas.colorPrimary,
            }"
            tooltip="always"
          />
        </div>
        <v-card-actions>
          <v-btn color="primary" depressed @click.stop="setupVolume = false">{{
            $t('glob_close')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import spoke from '~/utils/spoke'

export default {
  data() {
    return {
      setupVolume: false,
      volume: 100,
      formatter: (value) => {
        return value + '%'
      },
    }
  },
  watch: {
    volume(value) {
      spoke.mediaplayer.setVolume(value / 100)
    },
  },
  methods: {
    handleRapidCut() {
      if (this.volume > 0) {
        this.volume = 0
      } else {
        this.volume = 50
      }
    },
  },
}
</script>

<style lang="scss">
.w-volume-controller {
  position: relative;

  &__popover {
    max-width: 380px;
  }

  &__slider {
    width: 200px;
  }
}
</style>
