import ExtendableError from 'es6-error'

export class CoreError extends ExtendableError {
  constructor(message = 'undefined error') {
    super(`SpokeCore error: ${message}`)
  }
}

export class ConceptionError extends ExtendableError {}

export class ConceptionPropertyError extends ConceptionError {
  constructor(name, expected, passed) {
    const message = `invalid property type on ${name}, expecting type ${expected}, passed ${typeof passed}`

    super(message)
  }

  toString() {
    return `[${this.name}] ${this.message}`
  }
}

export class ConfigError extends ExtendableError {
  constructor(key, property, expecting) {
    let message = `SpokeConfig error: ${key} property is invalid, passed ${property}`

    if (expecting) {
      message += `, expecting ${expecting}`
    }

    super(message)
    this.key = key
    this.property = property
  }
}

export class NotImplementedError extends ExtendableError {
  constructor(method) {
    super(`Not implemented method ${method}`)
  }
}

export class NetworkError extends ExtendableError {
  constructor(status, response = {}) {
    super(`SpokeNetwork error ${status}`)
    this.status = status
    this.response = response
  }
}

export class UnsupportedMethodError extends ExtendableError {
  constructor(method) {
    super(`Unsupported method ${method}`)
  }
}

export class UnsupportedPropertyError extends ExtendableError {
  constructor(obj, passed, authorized = null) {
    const [prop = '?'] = Object.keys(obj)
    let message = `Unsupported value for property ${prop}, passed ${passed}, expecting `

    if (Array.isArray(authorized)) {
      message += authorized.join(',')
    } else {
      message += authorized
    }

    super(message)
  }
}
