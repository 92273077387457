import $get from 'lodash.get'
import localDB from '~/@mediam/spoke-js/services/LocalDB'

import spoke from '~/utils/spoke'

/**
 * temporary middleware
 * from rc9, localstorage is no longer used
 * here, we will check if the old value "session.token" is present in the localstorage,
 * create a session cookie spoke_stok (spoke sessiontoken) to enable authentication via spoke
 * old localstored token will be definitely removed
 *
 * ! this middleware should be removed after June 2021 (+3 months from now)
 */
export default function (context) {
  try {
    if (localStorage) {
      const spokefromls = localStorage.getItem('spoke')

      if (spokefromls) {
        try {
          const parsedSpokeFromLS = JSON.parse(spokefromls)
          const sid = $get(parsedSpokeFromLS, 'session.token')

          localDB.storeToken(sid)
          spoke.user.session.retrieveTokenFromStore()
        } catch (error) {
          // noop
          // console.error(error)
        } finally {
          localStorage.removeItem('spoke')
        }
      }
    }
  } catch (e) {
    console.warn('Could not get token from localstorage', e)
    localDB.storeToken(spoke.user.session.token)
  }
}
