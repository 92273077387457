import mixpanelHelper from '~/utils/mixpanel'

function installGATracker(token) {
  if (document.getElementById('bb-ga-tracker')) {
    window.ga = window.__ga

    return window.ga
  } else {
    delete window.ga
    ;(function (i, s, o, g, r, a, m) {
      // eslint-disable-next-line
      i['GoogleAnalyticsObject'] = r
      // eslint-disable-next-line
      ;(i[r] =
        i[r] ||
        function () {
          ;(i[r].q = i[r].q || []).push(arguments)
        })((i[r].l = 1 * new Date()))
      // eslint-disable-next-line
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      a.id = 'bb-ga-tracker'
      m.parentNode.insertBefore(a, m)
    })(
      window,
      document,
      'script',
      'https://www.google-analytics.com/analytics.js',
      'ga'
    )

    const ga = window.ga
    ga('create', token, {
      cookieExpires: 31536000, // 12 months, in seconds
    })
    ga('set', 'anonymizeIp', true)

    return ga
  }
}

function removeGATracker() {
  if (document && document.getElementById('bb-ga-tracker')) {
    document.getElementById('bb-ga-tracker').outerHTML = ''
  }
}

function installMPTracker(token) {
  const mixpanel = require('mixpanel-browser')
  const mpOptions = {
    api_host: 'https://api-eu.mixpanel.com',
  }
  mixpanelHelper.configure(mixpanel)
  mixpanelHelper.client.init(token, mpOptions)

  return mixpanelHelper
}

function installMTOTracker(token) {
  const _paq = (window._paq = window._paq || [])

  if (document.getElementById('bb-mto-tracker')) {
    return _paq
  }

  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking'])

  const u = '//mto.mediameeting.tech/'
  _paq.push(['setTrackerUrl', u + 'matomo.php'])
  _paq.push(['setSiteId', token])
  const d = document
  const g = d.createElement('script')
  const s = d.getElementsByTagName('script')[0]

  g.type = 'text/javascript'
  g.async = true
  g.src = u + 'matomo.js'
  g.id = 'bb-mto-tracker'
  s.parentNode.insertBefore(g, s)

  return _paq
}

function removeMTOTracker() {
  if (document && document.getElementById('bb-mto-tracker')) {
    document.getElementById('bb-mto-tracker').outerHTML = ''
  }
}

const noop = (_) => _

export default (context, inject) => {
  const { app } = context

  const { optEnableMetricsTrackers } = context.store.state.root

  const gaToken = app.$voicer.getConfig('analytics.ga', false)
  const mpToken = app.$voicer.getConfig('analytics.mixpanel', false)
  const mtoToken = app.$voicer.getConfig('analytics.matomo', false)

  class Tracking {
    // pseudo priv
    _gaActive = optEnableMetricsTrackers && !!gaToken
    _mpActive = optEnableMetricsTrackers && !!mpToken
    _mtoActive = optEnableMetricsTrackers && !!mtoToken

    // pseudo pub
    disabled = optEnableMetricsTrackers === false

    constructor() {
      // ga part
      if (this.gaActive) {
        this.ga = installGATracker(gaToken)
      } else {
        // tricky
        window.__ga = window.ga
        window.ga = noop
      }

      // mixpanel part
      if (this.mpActive) {
        this.mp = installMPTracker(mpToken)
      }

      if (this.mtoActive) {
        this.mt = installMTOTracker(mtoToken)
      }

      app.router.afterEach((to, from) => {
        mixpanelHelper.visit({ from: from.fullPath, to: to.fullPath })
      })
    }

    get gaActive() {
      return this._gaActive
    }

    set gaActive(status) {
      if (gaToken === false) {
        return
      }

      if (status === false) {
        // disable GA
        window[`ga-disable-${gaToken}`] = true
        window.__ga = window.ga
        window.ga = noop
        removeGATracker()
      } else {
        // enable GA
        window[`ga-disable-${gaToken}`] = false
        installGATracker(gaToken)
      }
    }

    get mpActive() {
      return this._mpActive
    }

    set mpActive(status) {
      if (mpToken === false) {
        return
      }

      if (status === false) {
        // disable MP
        if (mixpanelHelper.isConfigured && mixpanelHelper.client) {
          mixpanelHelper.client.opt_out_tracking()
        }
      } else {
        // enable MP
        if (mixpanelHelper.isConfigured === false) {
          installMPTracker(mpToken)
        }
        setTimeout(() => {
          if (mixpanelHelper.client) {
            mixpanelHelper.client.opt_in_tracking()
          }
        }, 0)
      }
    }

    get mtoActive() {
      return this._mtoActive
    }

    set mtoActive(status) {
      if (mtoToken === false) {
        return
      }
      if (status === false) {
        window._paq.push(['optUserOut'])
        removeMTOTracker()
      } else {
        installMTOTracker(mtoToken)
        window._paq.push(['forgetUserOptOut'])
      }
    }

    identifyUser() {
      if (app.$spoke.site.optAuthentication === false) {
        mixpanelHelper.identify(
          app.$spoke.user.session.id,
          app.$spoke.user.session.toJSON()
        )
      } else {
        mixpanelHelper.identify(app.$spoke.user.id, app.$spoke.user.toJSON())
      }
    }

    captureException(exception) {
      mixpanelHelper.exception(exception)
    }

    disableMetricTrackers() {
      if (this.disabled === false) {
        this.disabled = true
        this.gaActive = false
        this.mpActive = false
        this.mtoActive = false
        app.$spoke.eventManager.emit('disable_metrics_trackers', {
          ga: false,
          mp: false,
          mto: false,
        })
      }
    }

    enableMetricTrackers() {
      if (this.disabled === true) {
        this.disabled = false
        this.gaActive = true
        this.mpActive = true
        this.mtoActive = true
        app.$spoke.eventManager.emit('enable_metrics_trackers', {
          ga: true,
          mp: true,
          mto: true,
        })
      }
    }
  }

  inject('tracking', new Tracking())
}
