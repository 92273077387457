import $clone from 'lodash.clone'
import $get from 'lodash.get'
import $merge from 'lodash.merge'
import VimeoPlayer from '@vimeo/player'

import Analytics from '../../services/Analytics'
import EventManager from '../../services/EventManager'
import nextTick from '../../utils/next-tick'
import MediaPlayer from '../../services/MediaPlayer'
import CommonMedia from './Media'

// note(dev):
// events are manually triggered to concord with
// the mediaplayer (audio inherited) standard
const VIDEO_EVENTS = [
  // 'loading',
  // 'loaded',
  // 'ended', // manual
  // 'playing',
  // 'pause', // manual
  // 'seeking',
  // 'seeked',
  // 'bufferstart',
]

/**
 * @class Video
 * @extends Model
 * @description
 * Video model
 *
 * @todo
 * implement play method
 * User:play -> Server:authentify & generate tmp token -> User:fetch media with token
 *
 * @todo
 * should be commented (good first commit)
 */
const DEFAULT_MODEL = {
  value: '',
  type: 'video',
  metadatas: {
    duration: 0,
    position: 0,
    title: 'Unknown',
  },
}

class CommonVideo extends CommonMedia {
  static modelName = 'Video'
  static modelDefaults = DEFAULT_MODEL

  /**
   * @api private
   * @description
   * a HTMLFragmentElement (dom related), set when explicitLoad is called
   * persist until "unload" is called
   */
  #element = null

  /**
   * @api private
   * @description
   * set to true during the explicitLoad
   * prevent double loads
   */
  #inBoot = false

  /**
   * @private
   * explicitLoad options are saved here
   */
  #passedOptions = {}

  /**
   * @api private
   */
  #vimeoBooted = false

  /**
   * @api private
   * @description
   * attached content (yes, it's a circular reference)
   */
  #content = null

  /**
   * @api private
   * @description
   * episode index (array  index in the parent content epideos property)
   */
  #episodeIndex = 0

  /**
   * @api private
   * @see load method
   * @see VimeoPlayer
   */
  #player = null

  /**
   * @api private
   * @see playWhenBooted()
   * @description
   * If true, video will be played (aka play method is called) once #vimeoBooted = true
   */
  #mustPlayWhenBooted = false

  /**
   * @api public
   * @description
   * status of the current player
   * can have the following values
   * - unload
   * - loading
   * - load
   * - loaded
   * - error
   * - pause
   * - play
   * - stop
   * default value is `unload`
   */
  status = 'unload'

  static resource = 'videos'

  get content() {
    return this.#content
  }

  get currentTime() {
    return this.#player ? this.#player.getCurrentTime() : 0
  }

  get element() {
    return this.#element
  }

  get episodeIndex() {
    return this.#episodeIndex
  }

  get duration() {
    return this.$metadata('duration', 0)
  }

  get isPaused() {
    return this.status === 'pause'
  }

  get isPlaying() {
    return this.status === 'play'
  }

  get isStopped() {
    return this.status === 'stop'
  }

  get isAudio() {
    return false
  }

  get isVideo() {
    return true
  }

  get playerInstance() {
    return this.#player
  }

  get volume() {
    if (this.#player) {
      return this.#player.getVolume()
    }
    return 0
  }

  set volume(v) {
    if (this.#player) {
      return this.#player.setVolume(v)
    }
  }

  constructor(data, content, index) {
    super($merge({}, DEFAULT_MODEL, data), { content })

    this.#content = content
    this.#episodeIndex = index

    // duplicate from Audio.contructor
    this.on('error', (error) => {
      const payload = {
        id: this.id,
        mediaDuration: this.duration,
        mediaSource: this.$data('value'),
        mediaTitle: this.$metadata('title', 'unknown'),
        error,
      }
      EventManager.emit('media_error', payload)
    })
  }

  propagateEvent(eventName, context) {
    return (data) => {
      context.emit(eventName, data)
    }
  }

  dispatchEvent(eventName) {
    return (data) => {
      this.propagateEvent(eventName, this)(data)
      this.propagateEvent(
        eventName,
        MediaPlayer
      )({ content: this.#content, data })
    }
  }

  // overrided parent
  // if the player exists, "loaded" must immediatly send the cb
  on(eventName, cb, context) {
    if (['loaded'].includes(eventName) && this.#vimeoBooted) {
      return nextTick(() => cb())
    } else {
      return super.on(eventName, cb, context)
    }
  }

  // override parent
  // if the player exists, "loaded" must immediatly send the cb
  once(eventName, cb, context) {
    if (['loaded'].includes(eventName) && this.#vimeoBooted) {
      return nextTick(() => cb())
    } else {
      return super.once(eventName, cb, context)
    }
  }

  // ! important notice
  // on media models, the load method is agnostic and will be invoked no matter what happens by
  // the Mediaplayer when initializing a content.
  // Except that it should not lead to specific actions in the case of vimeo files,
  // the load must be initialized once the video component is mounted
  // @see excentrics/shells/bb-default/components/CVimeoPlayerCard/index.vue
  // however, another "explicitLoad" method is defined below and will be invoked by the component.
  // this method saves the passed arguments, so that it is possible to restore the player if needed
  load() {
    // noop
    return Promise.resolve()
  }

  // @see "load" comment to understand why we need this
  explicitLoad(element, options = {}) {
    if (this.#inBoot === true) {
      return
    }

    this.#inBoot = true
    this.status = 'loading'
    this.dispatchEvent('loading')()

    let source = $clone(this.data.value)
    source = source.replace('https://player.vimeo.com/video/', '')
    source = source.replace('https://vimeo.com/', '')

    // eslint-disable-next-line
    this.#element = element
    this.#passedOptions = options
    this.#player = new VimeoPlayer(element, {
      id: source,
      // must be true for iOS devices, if false (default), cause a NotAllowedError issue
      autoplay: Analytics.isIOS,
      controls: true,
      responsive: true,
      playsinline: false,
      pip: true,
      ...options,
    })

    this.#player.setVolume(MediaPlayer.volume)

    VIDEO_EVENTS.forEach((eventName) => {
      this.#player.on(eventName, (data) => this.dispatchEvent(eventName)(data))
    })

    const timeout = setTimeout(() => {
      this.emit('error', new Error('timed_out'))
    }, 15000) // after 15s, a timeout is emitted

    this.#player.on('loaded', () => {
      clearTimeout(timeout)
      this.status = 'loaded'
      this.#inBoot = false
      this.#vimeoBooted = true

      this.dispatchEvent('load')()
      this.dispatchEvent('loaded')()
      this.dispatchEvent('booted')()

      if (this.#mustPlayWhenBooted === true) {
        nextTick(() => {
          this.play()
        })
      }
    })

    const onPause = () => {
      // Realtime.publish('track', { media: { status: 'stop' } })
      this.status = 'pause'
      this.dispatchEvent('pause')()
    }

    // fired when the media is played
    // will fire a rt event
    const onPlay = () => {
      if (this.status !== 'play') {
        this.status = 'play'
        this.dispatchEvent('play')()
      }
    }

    this.#player.on('bufferstart', (data) => {
      this.dispatchEvent('loading')(data)
    })

    this.#player.on('error', async (error) => {
      if ($get(error, 'name') === 'NotAllowedError') {
        // there is no reason to cause an explicit error here
        // because this error suggest user device has not
        // already made an interaction with the vimeojs iframe
        try {
          const hasPP = await this.#player.getPictureInPicture()
          if (hasPP) {
            await this.#player.exitPictureInPicture()
          }

          const hasFS = await this.#player.getFullscreen()
          if (hasFS) {
            await this.#player.exitFullscreen()
          }
        } catch (error) {}
      } else {
        this.#inBoot = false
        this.dispatchEvent('error')(error)
        this.unload()
        this.status = 'error'
        console.log('ici')
      }
    })

    this.#player.on('ended', async () => {
      try {
        this.stop()
      } catch (error) {
        // silent error
      }
      if (Analytics.isIOS) {
        try {
          // weird but fix an ios issue
          // delay the 'end' notification after a silented exit occuring somewhere
          // between exitfullscreen & exitpictureinpicture
          setTimeout(() => {
            this.dispatchEvent('end')()
          }, 0)

          const hasPP = await this.#player.getPictureInPicture()
          if (hasPP) {
            await this.#player.exitPictureInPicture()
          }

          const hasFS = await this.#player.getFullscreen()
          if (hasFS) {
            await this.#player.exitFullscreen()
          }
        } catch (error) {
          // silent error
        }
      }

      if (Analytics.isIOS === false) {
        this.dispatchEvent('end')()
      }
    })

    this.#player.on('play', onPlay)
    this.#player.on('pause', onPause)

    this.#player.on('seeking', (...data) => {
      if (this.isPlaying === false) {
        this.dispatchEvent('seek')(...data)
      }
    })

    this.#player.on('seeked', (data) => {
      this.dispatchEvent('seek')(data)
    })

    this.#player.on('timeupdate', (data) => {
      this.dispatchEvent('timeupdate')({
        ...data,
        time: data.seconds, // standard
      })
    })

    this.#player.on('bufferstart', () => {
      this.dispatchEvent('loading')()
    })

    return this
  }

  pause() {
    if (this.#player) {
      this.status = 'pause'
      this.#player.pause()
    }

    return this
  }

  play() {
    return this.playIfBooted()
  }

  playIfBooted() {
    if (this.#vimeoBooted === true && this.#player) {
      this.emit('load')
      this.emit('booted') // emit booted event
      this.#player.play()
    } else {
      this.#mustPlayWhenBooted = true
      // if the previously registered element still exists in the dom
      // we can resurrect the previous vimeo instance
      // in this condition, user has manually changed the playing episode
      if (this.#element && document.body.contains(this.#element)) {
        this.resurrect()
      }
    }

    return Promise.resolve()
  }

  async seek(time) {
    if (this.#player) {
      const isPlaying = this.isPlaying

      try {
        this.emit('loading')
        await this.#player.setCurrentTime(time)
      } catch (e) {
        if (isPlaying) {
          this.emit('play')
        } else {
          this.emit('stop')
        }
        // noop
      }
    }

    return this
  }

  stop() {
    if (this.#player) {
      this.status = 'stop'
      this.#player.pause()
      this.#player.setCurrentTime(0)
    }

    return this
  }

  unload() {
    this.#inBoot = false
    this.#mustPlayWhenBooted = false
    this.#vimeoBooted = false
    this.dispatchEvent('unload')()
    if (['loading', 'play'].includes(this.status)) {
      try {
        this.stop()
      } catch (error) {}
      this.dispatchEvent('timeupdate')({ percent: 0, time: 0 })
    }

    this.status = 'unload'

    if (this.#player) {
      this.#player.destroy()
    }

    nextTick(() => {
      this.#player = null
      this.removeAllListeners()
    })
    return this
  }

  resurrect() {
    if (
      !this.#player ||
      (this.#inBoot === false && this.#vimeoBooted === false)
    ) {
      return this.explicitLoad(this.#element, this.#passedOptions)
    }
  }
}

/**
 * ensure data is well formated (API-LTS compliant)
 * @param {object} data
 * @returns {boolean}
 */
CommonVideo.isValid = function (data) {
  return true // hmm.. well, todo
}

export default CommonVideo
