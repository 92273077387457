import EventEmitter from 'eventemitter3'
import { io } from 'socket.io-client/dist/socket.io'
import mqttMatch from 'mqtt-match'

const log = (...params) => {
  console.log(`[rt2 debug]-`, ...params)
}

class Realtime extends EventEmitter {
  /**
   * @api private
   * @description
   * usefull when you wanna debug realtime from a living webapp
   * usage with bubblecast:
   * ```
   * // in the developer console
   * window.$nuxt.$spoke.realtime.debug = true
   * ```
   * @note can be set via `.debug` setter
   */
  #__debug = false

  /**
   * @api private
   */
  #subscriptions = []

  /**
   * @api public
   * @description set via Core.#configureURIs
   */
  baseURL = null

  /**
   * @api public
   * @description required for socketio client
   * will be '/' if user has not defined a custom
   * connection uri
   */
  basePath = '/realtime/lts'

  get debug() {
    return this.#__debug
  }

  set debug(value) {
    if (process.env.NODE_ENV !== 'production') {
      this.#__debug = value

      if (this.#__debug === true) {
        this.#log(`realtime debug mode actived`)
      }
    } else {
      this.#log(`realtime debug mode is disabled in production`)
    }
  }

  /**
   * @api public
   * @param {string} siteId
   *
   * @returns
   * @memberof Realtime
   */
  configure(siteId) {
    this.siteId = siteId
    this.#log(`configured site`, this.siteId)

    return this
  }

  /**
   * @from spoke LTS-1:
   * This methods doesn't returns a Promise anymore
   * Must not be blocking from client side
   */
  connect(token) {
    this.#log('connection on', this.baseURL)
    this.client = io(this.baseURL, {
      transports: ['websocket', 'polling'],
      path: this.basePath,
      // onlyBinaryUpgrades: true,
      secure: true,
    })

    const authenticate = () => {
      const payload = {
        siteId: this.siteId,
        token,
      }

      return new Promise((resolve, reject) => {
        this.publish('authenticate', payload, (response) => {
          this.emit('authenticated', response)
          resolve(response)

          // @todo check if response is valid (define standard)
          // define strategy if authentication has failed
          // we must do different things according response
          // eg: if service error or unavailble -> retry
          // if token is malformed -> try to refresh a token -> retry
          // define a mechanic to ban multiple failures in this case
        })
      })
    }

    /**
     * resubscribe on previous active subscriptions
     */
    const resubscribe = () => {
      this.#subscriptions.forEach((sub) => {
        this.publish('subscribe', { topic: sub.topic })
      })
    }

    this.client.on('connect', () => {
      authenticate().then(() => {
        resubscribe()
      })
    })

    this.client.on('reconnect', () => {
      authenticate().then(() => {
        resubscribe()
      })
    })

    this.client.on('disconnect', (reason) => {
      this.#log('disconnect event', { reason })
    })

    this.client.on('connect_error', (error) => {
      this.#log('connect_error event', error)
    })

    this.client.on('error', (e) => {
      this.#log('error event', e)
    })

    this.client.on('implement_error', (message) => {
      this.#log('realtime implementation error', message)
    })

    this.client.on('broadcast', (message) => {
      const { channel, data } = message

      this.#subscriptions.forEach((sub) => {
        if (typeof sub.topic !== 'string' || typeof channel !== 'string') {
          return false
        }

        if (typeof sub.handler !== 'function') {
          return false
        }

        if (mqttMatch(sub.topic, channel)) {
          try {
            sub.handler(data)
          } catch (error) {
            console.error(`error on topic`, sub.topic, error)
            this.emit('parsing_error', error)
          }
        }
      })
    })
  }

  publish(topic, payload, fn) {
    if (this.__ready === false) {
      // spoke not ready, prevent anonymous call
      console.warn(`[out_call] spoke is not ready`)
      return Promise.resolve()
    }

    if (!this.client) {
      console.warn(`[out_call] spoke rt client is not ready`)
      return Promise.resolve()
    }

    this.#log('publish on topic', topic)

    try {
      if (!fn) {
        fn = (data) => {
          this.#log(`response on ${topic}`, data)
        }
      }

      this.client.emit(topic, payload, fn)
      return Promise.resolve({ topic, payload })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @param {function} handler
   * @returns
   * @memberof Realtime
   */
  watch(topic, handler) {
    this.#log('subscribe', 'subscribing topic', topic)
    this.#subscriptions.push({ topic, handler })
    this.publish('subscribe', { topic })

    return this
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @returns
   * @memberof Realtime
   */
  unwatch(topic) {
    this.#log('unsubscribe', 'unsubscribing topic', topic)
    this.#subscriptions = this.#subscriptions.filter(
      (sub) => sub.topic !== topic
    )
    this.publish('unsubscribe', { topic })

    return this
  }

  end() {
    this.client.close()
    return this
  }

  #log(...params) {
    if (this.debug === true) {
      log(...params)
      return true
    }

    return false
  }
}

export default new Realtime()
