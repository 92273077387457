/**
 * This is the V2 of `comments` store model
 * Don't use `comments` if you're before RC9
 * or you're using the `bb-default` shell
 */

import $merge from 'lodash.merge'
import _actions from './_actions'
import _getters from './_getters'
import _mutations from './_mutations'
import _state from './_state'

import { runExcentricFile } from '~/utils/voicer'

const INITIAL_STATE = () => ({ ..._state })

const excentrics = runExcentricFile('store', 'messages')

export const state = () => ({
  ...$merge(INITIAL_STATE(), excentrics.state),
})

export const actions = $merge(_actions, excentrics.actions)
export const getters = $merge(_getters, excentrics.getters)
export const mutations = $merge(_mutations, excentrics.mutations)
