import EventEmitter from 'eventemitter3'

import nextTick from '../utils/next-tick'
import uidGenerator from '../utils/uid'
import warn from '../utils/warning'
import Garbage from './Garbage'

/**
 * @class
 * @example
 *
 * const contents = spoke.collection('contents').items()
 *
 * spoke.items(contents).get()
 */
export default class PseudoArray extends EventEmitter {
  /**
   * @api private
   * @type {string}
   * unique pseudo array identifier
   */
  #__uaid__ = uidGenerator()

  /**
   * @api private
   * @type {Set}
   * list of uids present in items
   */
  #uids = new Set()

  /**
   * @api private
   * @type {Date}
   * initialization date
   */
  #createdAt = new Date()

  /**
   * @api private
   * @reference {Collection}
   */
  #collection = undefined

  /**
   * @api private
   * list of items
   */
  #items = []

  /**
   * @api private
   * @type {Boolean}
   * `true` when a lean operation is made (to prevent double lean)
   */
  #isLean = false

  /**
   * @api private
   * @reference {PseudoQuery}
   */
  #pseudoQuery = undefined

  /**
   * @api private
   * current items length
   */
  #size = 0

  get __uaid__() {
    return this.#__uaid__
  }

  constructor(pseudoQuery, collection, size, defaultValues) {
    super()
    this.#collection = collection
    this.#pseudoQuery = pseudoQuery

    for (let i = 0; i < size; i++) {
      const document = this.#collection.create(
        Array.isArray(defaultValues) ? defaultValues[i] : defaultValues
      )

      this.push(document)
    }
    this.#size = size
  }

  get __uqid__() {
    return this.#pseudoQuery.__uqid__
  }

  get length() {
    return this.#size
  }

  get size() {
    return this.#size
  }

  get isLeanified() {
    return this.#isLean
  }

  $rehydrate(items) {
    if (items.length > this.length) {
      for (let i = Math.max(this.length - 1, 0); i < items.length; i++) {
        const document = this.#collection.create(items[i])

        if (this.#isLean) {
          this.push(document.lean())
        } else {
          this.push(document)
        }
      }

      this.#size = this.#items.length
    } else if (items.length < this.length) {
      this.#items = this.#items.splice(0, items.length)
      this.#size = items.length
    }

    items.forEach((item, index) => {
      if (this.#isLean) {
        const item = Garbage.find(this.#items[index])

        item.$rehydrate(items[index])
        this.#items[index] = item.lean()
      } else {
        this.#items[index].$rehydrate(items[index])
      }
    })

    nextTick(() => this.emit('updated'))

    return this
  }

  delete(uid) {
    this.#uids.delete(uid)
    this.#items = this.#items.filter((item) => item.__uid__ !== uid)

    this.#size -= 1
  }

  fat() {
    return this.toArray()
  }

  filter(...props) {
    return this.#items.filter(...props)
  }

  find(...props) {
    return this.#items.find(...props)
  }

  findById(id) {
    return this.findFromId(id)
  }

  findFromId(id) {
    return this.find((d) => d.id === id)
  }

  forEach(...props) {
    return this.#items.forEach(...props)
  }

  has(uid) {
    return this.#uids.has(uid)
  }

  index(index) {
    return this.#items[index]
  }

  join(...props) {
    return this.#items.join(...props)
  }

  lean(properties) {
    if (this.#isLean) {
      return this
    }

    this.#items = this.#items.map((document) => {
      if (!document.lean) {
        warn(
          true,
          `document.lean has been called on a previous step, lean() should be unique, PseudoArray doesn't keep references on fat objects`
        )

        return document
      }
      return document.lean(properties)
    })
    this.#isLean = true

    return this
  }

  map(...props) {
    this.#items = this.#items.map(...props)

    return this
  }

  push(document) {
    // todo
    // remove item from lists ?
    // update size ?
    // document.on('destroy', () => {
    // })

    this.#uids.add(document.__uid__)
    this.#items.push(document)

    return document
  }

  pushUnique(document) {
    if (this.has(document.__uid__) === false) {
      return this.push(document)
    }

    return false
  }

  reverse(...props) {
    this.#items.reverse(...props)

    return this
  }

  sort(...props) {
    this.#items = this.#items.sort(...props)

    return this
  }

  toArray() {
    return [...this.#items]
  }

  toString() {
    return this.join()
  }

  destroy() {
    this.emit('destroy')

    Object.keys(this._events).forEach((event) => {
      this.off(event)
    })

    this.#items.forEach((item) => {
      const elm = Garbage.find(item)

      if (elm) {
        elm.destroy()
      }
    })

    this.#items = []
    this.#size = 0

    return this
  }

  [Symbol.hasInstance](instance) {
    return Array.isArray(instance)
  }

  [Symbol.toPrimitive]() {
    return this.#__uaid__
  }

  /**
   * @generator
   * @returns {Symbol.iterator}
   */
  *[Symbol.iterator]() {
    const items = [...this.#items]

    while (items.length) {
      const item = items.shift()

      yield item
    }
  }
}
