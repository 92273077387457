<template>
  <v-container
    :class="{
      'pa-0': fullsize || $vuetify.breakpoint.xsAndDown,
      'w-layout-modal--fullsize': fullsize || $vuetify.breakpoint.xsAndDown,
      'w-layout-modal--mobile': $vuetify.breakpoint.xsAndDown,
      'w-layout-modal--theme--dark': $voicer.isDarkTheme,
      'w-layout-modal--theme--light': $voicer.isLightTheme,
    }"
    class="w-layout-modal"
    fill-height
  >
    <v-layout row wrap align-center justify-center>
      <v-flex class="w-layout-modal-flex" v-bind="sizes">
        <div
          v-if="$slots['heading'] && noHeading === false"
          class="w-layout-modal__heading"
        >
          <slot name="heading" />
        </div>
        <div
          v-else-if="noHeading === false"
          class="w-layout-modal__heading pb-3"
        >
          <w-logo size="24" />
          <h1 class="title" v-text="headTitle" />
        </div>
        <v-card v-bind="props" class="w-layout-modal__card">
          <v-card-title
            v-if="$slots.title && noTitle === false"
            class="pl-0 pr-0 pb-3"
          >
            <slot name="title" />
          </v-card-title>
          <v-divider v-if="$slots.title && noTitle === false" />
          <slot name="before-content" />
          <v-card-text
            v-if="$slots.content"
            class="grow w-layout-modal__content pa-0"
          >
            <slot name="content" />
          </v-card-text>
          <v-card-actions
            v-if="$slots.actions"
            class="w-layout-modal__actions pa-0 mt-4"
          >
            <slot name="actions" />
          </v-card-actions>
          <slot name="after-content" />
        </v-card>
        <div class="w-layout-modal__footer">
          <b-footer no-padding />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    props: {
      type: Object,
      default: () => ({
        flat: true,
        tile: true,
      }),
    },
    fullsize: {
      type: Boolean,
      default: false,
    },
    noHeading: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    headTitle() {
      return this.title || this.$metadatas.siteHeadTitle
    },
    sizes() {
      const normal = {
        xl3: true,
        lg4: true,
        md6: true,
        sm8: true,
        xs12: true,
      }
      const large = {
        md8: true,
        sm12: true,
        xs12: true,
      }

      if (this.fullsize) {
        return {
          xl12: true,
        }
      }

      return this.size === 'normal' ? normal : large
    },
  },
}
</script>

<style lang="scss">
.w-layout-modal {
  @media screen and (min-width: 960px) {
    .w-layout-modal-flex {
      flex: 1 1 auto;
      max-width: 70%;
    }
  }

  &--theme--light {
    &__baseline a {
      color: rgba(220, 220, 220, 0.86) !important;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
      margin-left: 8px;
    }
  }

  &--fullsize {
    .flex {
      position: fixed;
      z-index: 5;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 6;
    }
  }

  &__card {
    height: 100% !important;

    &__text {
      max-height: calc(100vh - 106px);
      overflow: scroll;
    }
  }

  .flexcard {
    border: 1px #ddd solid;
  }

  &--mobile {
    .flexcard {
      border: 0;
      border-top: 1px #ddd solid;
    }
  }

  .text {
    padding: 15px;
    font-size: 1.1em;
  }
}
</style>
